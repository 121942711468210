@import "src/assets/stylesheets/mixins";

.colorPicker {
  @include themify($themes) {
    position: relative;
    .picker {
      position: absolute;
      top: 30px;
    }

    :global(.chrome-picker) {
      > div {
        background: themed("mainBackgroundColor");
      }

      svg {
        fill: themed("textColor") !important;
        background: none !important;
        &:hover {
          fill: themed("tintColor") !important;
        }
      }

      input {
        background: themed("inputBackgroundColor");
        color: themed("textColor") !important;
        height: auto !important;
        border: none !important;
        box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
          0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3),
          inset 0 1px 1px rgba(16, 22, 26, 0.4) !important;
        padding: 6px;
        outline: none;
      }
    }

    .circle {
      cursor: pointer;
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }

    .buttonGroup {
      background: themed("mainBackgroundColor");
      .btn {
        box-shadow: rgba(0, 0, 0, 0.3) 0 0 2px, rgba(0, 0, 0, 0.3) 0 4px 8px;
        margin-right: 0 !important;
        flex: 1;
      }
    }
  }
}
