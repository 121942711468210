@import "src/assets/stylesheets/variables";
@import "src/assets/stylesheets/mixins";

.dialog :global(.bp3-dialog-header) {
  @include themify($themes) {
    background: themed('mainBackgroundColor');
    padding: 15px;
    border-bottom: 1px solid themed('blocksBorderColor');
    button {
      outline: none;
      &:hover {
        color: themed('tintColor');
      }
    }
    :global(.bp3-heading), button {
      color: themed('textColor');
    }
  }
}

.dialog:global(.bp3-dialog) {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  @include themify($themes) {
    background: themed('mainBackgroundColor');
    color: themed('textColor') !important;
  }
}
