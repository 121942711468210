@import "src/assets/stylesheets/mixins";
@import "src/assets/stylesheets/mixins";

.inputGroup {
  outline: none;

  :global(.bp3-input):not([type="search"]) {
    padding: 6px;
    height: unset;
  }
  * {
    pointer-events: auto!important;
  }
  @include themify($themes) {
    textarea, input {
      color: themed('textColor');
      background: themed('inputBackgroundColor');
    }
    textarea:focus, input:focus {
      box-shadow: inset 0 0 2px themed('focusedInputInsetColor');
      outline: none;

      &:not([type="search"]) {
        height: auto;
        padding: 6px;
      }
    }
  }
}
