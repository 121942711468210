@import "src/assets/stylesheets/mixins";
@import "src/assets/stylesheets/animations";

.community {
  p {
    text-align: left;
    font-size: 12px;
  }

  .mail {
    @include themify($themes) {
      color: themed('tintColor');
    }
  }

  .section {
  }
  .gremlinFloat {
    width: 100px;
    animation: float 3s ease-in-out infinite;
  }
  .imgBig {
    width: 150px;
  }
}
