@import "src/assets/stylesheets/mixins";
@import "src/assets/stylesheets/animations";

.sideBar {
  display: flex;

  > div {
    //padding: 5px;
  }
}
