@import "src/assets/stylesheets/mixins";

.metadata {
  @include themify($themes) {
    .formGroup {
      text-align: left;
    }

    .privacyWrapper {
      text-align: left;
    }
  }
}
