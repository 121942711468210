@import "src/assets/stylesheets/mixins";

.emptyState {
  @include themify($themes) {
    .nonIdealDesc {
      .create {
        color: themed('tintColor');
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    .learnMore {
      margin: 30px 0;
      font-size: 11px;
    }
  }
}
