@import "src/assets/stylesheets/mixins";

div.mimic {
  @include themify($themes) {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: themed('mainBackgroundColor');
    .content {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: absolute;
      width: 100%;
    }

    .ghostButton {
      visibility: hidden;
    }
  }
}
