@import "src/assets/stylesheets/mixins";
@import "src/assets/stylesheets/animations";

.globalLoader {
  animation: fade-in 0.4s ease-in;
  @include themify($themes) {
    background: themed("mainBackgroundColorA");
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    top: 0;
    left: 0;
  }
}
