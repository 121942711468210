@import "src/assets/stylesheets/mixins";
:global(.ace_tooltip.ace_doc-tooltip) {
  background: #3d3f41;
  display: flex !important;
  flex-direction: column;
  padding: 8px;
  border: none;
}

.editorContainer {
  @include themify($themes) {
    flex: 1;
    position: relative;
    height: 100%;

    .gremlifyEditor:global(.ace_editor) {
      font-size: 1em;
      background: themed('mainBackgroundColor');
      * {
        font: 14px/normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace !important;
      }
    }

    :global(.ace_string) {
      color: themed('tintColor');
    }

    :global(.ace_identifier), :global(.ace_function) {
      color: themed('functionColor');
    }

    :global(.ace_numeric) {
      color: themed('danger');
    }

    :global(.ace_search) {
      background: themed('mainBackgroundColor');
      border: none;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
      color: themed('textColor');
      input {
        background: themed('inputBackgroundColor');
        padding: 5px;
        border: none;
        color: themed('textColor');
        outline: none;
      }
      :global(.ace_nomatch) {
        outline: none;
      }
      :global(.ace_searchbtn), :global(.ace_button) {
        background: themed('mainBackgroundColor');
        color: themed('textColor');
        padding: 5px;
        border: none;
      }
    }

    :global(.ace_gutter) {
      background: none;
    }
    .upload {
      min-width: 0;
      min-height: 0;
      padding: 2px 6px;
    }
  }
}
:global(.ace_content) {
  @include themify($themes) {
    background: themed('mainBackgroundColor');
  }
}

:global(.ace_completion-highlight) {
  @include themify($themes) {
    color: themed('tintColor') !important;
  }
}

:global(.ace_autocomplete) {
  :global(.ace_line) {
    color: white !important;
  }
  :global(.ace_active-line) {
    @include themify($themes) {
      background: themed('updateBackgroundColor') !important;
    }
  }
}
