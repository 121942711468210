@import "src/assets/stylesheets/mixins";

.table {
  border-spacing: 0;
  border-collapse:collapse;
  border-radius: 5px;
  width: 100%;
  flex: 1;

  @include themify($themes) {

    .thead {
      position: sticky;
      top: 0;
      .tr {
        border: none;
      }
    }

    .th {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      background: themed('tableHeaderBackgroundColor');
      border-right: 1px solid themed('tableHeaderBorderColor');
      border-top: 1px solid themed('tableHeaderBorderColor');
      border-bottom: 1px solid themed('tableHeaderBorderColor');
      &:first-child {
        border-left: 1px solid themed('tableHeaderBorderColor');
      }
      color: themed('tableHeaderTextColor');

      :global(.bp3-icon) {
        margin-left: 5px;
      }
    }

    .tr {
      border-bottom: 1px solid themed('tableCellBorderColor');
      border-left: 1px solid themed('tableCellBorderColor');
      border-right: 1px solid themed('tableCellBorderColor');
    }

    .td, .th {
      padding: 6px;
    }
  }
}
