@import "./variables";

$themes: (
  dark: (
    mainBackgroundColor: #1a1e22,
    mainBackgroundColorA: rgba(26, 30, 34, 0.81),
    inputBackgroundColor: #151a1d,
    loginBackgroundColor: #2c353c,
    tableOddRowColor: #2c353c,
    tintColor: #6ab04c,
    tintDarkerColor: #579a3a,
    sideBarItemMainColor: #d3d3d3,
    textColor: #d3d3d3,
    spinnerColor: white,
    altTextColor: white,
    blocksBorderColor: #060809,
    focusedInputInsetColor: rgba(0, 0, 0, 0.9),
    updatesDotColor: #e74c3c,
    updateBackgroundColor: rgba(138, 155, 168, 0.05),
    graphBackgroundColor: #1a1e22,
    buttonBackgroundColor: #1a1e22,
    toolbarBackgroundColor: #1a1e22,
    danger: #e74c3c,
    functionColor: #ed4c67,
    sectionTitleBackgroundColor: #171b1f,
    primaryButtonBackgroundColor: #151a1d,
    dangerButtonBackgroundColor: #e74c3c,
    contextMenuBackgroundColor: #151a1d,
    contextMenuTextColor: #1a1e22,
    menuTextColor: #1a1e22,
    menuDividerColoR: rgba(26, 30, 34, 0.1),
    contextMenuBorderColor: #141719,
    secondaryMenuBackgroundColor: #ffffff,
    secondaryMenuBackgroundColorHover: #c3c3c3,
    primaryMenuBackgroundColor: #1a1e22,
    primaryMenuBackgroundColorHover: rgba(26, 30, 34, 0.81),
    tableCellBackgroundColor1: #25292d,
    tableCellBackgroundColor2: transparent,
    tableCellBorderColor: #131313,
    tableHeaderBorderColor: #060809,
    tableHeaderBackgroundColor: #181c20,
    tableHeaderTextColor: #d3d3d3,
    workspaceCardFooter: #151a1d,
    switchOffBackgroundColor: rgba(16, 22, 26, 0.5),
    stringTypeColor: #3498db,
    booleanTypeColor: #e74c3c,
    numberTypeColor: #16a085,
  ),
);
