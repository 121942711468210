@import "src/assets/stylesheets/variables";
@import "src/assets/stylesheets/mixins";

.history {
  padding: 10px;
  @include themify($themes) {
    text-align: left;
    z-index: 11;
    .historyList {
      max-height: 650px;
      overflow-y: auto;
      padding: 0;
      list-style-type: none;

      li {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      .historyItem {
        cursor: pointer;
        margin-bottom: 10px;
        .query {
          flex: 1;
          white-space: pre-wrap;
          font-family: Courier, serif;
          margin: 0;
          font-size: 12px;

          &:hover {
            color: themed('tintColor');
          }
        }
      }
    }
  }
}
