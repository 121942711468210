@import "src/assets/stylesheets/mixins";
@import "src/assets/stylesheets/animations";

.cloudSpinner {
  animation: fade-in 0.4s ease-in;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .cloudSpinnerSvg {
    @include themify($themes) {
      stroke: themed("spinnerColor");
    }
    .enpo164tlyaj7_ts {
      animation: enpo164tlyaj7_ts__ts 1640ms linear infinite alternate forwards;
    }

    @keyframes enpo164tlyaj7_ts__ts {
      0% {
        transform: translate(8.46369px, 32.477px) scale(0, 0);
      }
      54.878049% {
        transform: translate(8.46369px, 32.477px) scale(0, 0);
      }
      67.073171% {
        transform: translate(8.46369px, 32.477px) scale(1, 1);
      }
      100% {
        transform: translate(8.46369px, 32.477px) scale(1, 1);
      }
    }

    .enpo164tlyaj8_ts {
      animation: enpo164tlyaj8_ts__ts 1640ms linear infinite alternate forwards;
    }

    @keyframes enpo164tlyaj8_ts__ts {
      0% {
        transform: translate(29.1308px, 49.2075px) scale(0, 0);
      }
      42.682927% {
        transform: translate(29.1308px, 49.2075px) scale(0, 0);
      }
      54.878049% {
        transform: translate(29.1308px, 49.2075px) scale(1, 1);
      }
      100% {
        transform: translate(29.1308px, 49.2075px) scale(1, 1);
      }
    }

    .enpo164tlyaj9_ts {
      animation: enpo164tlyaj9_ts__ts 1640ms linear infinite alternate forwards;
    }

    @keyframes enpo164tlyaj9_ts__ts {
      0% {
        transform: translate(58.8522px, 50.979px) scale(0, 0);
      }
      42.682927% {
        transform: translate(58.8522px, 50.979px) scale(0, 0);
      }
      54.878049% {
        transform: translate(58.8522px, 50.979px) scale(1, 1);
      }
      100% {
        transform: translate(58.8522px, 50.979px) scale(1, 1);
      }
    }

    .enpo164tlyaj11_ts {
      animation: enpo164tlyaj11_ts__ts 1640ms linear infinite alternate forwards;
    }

    @keyframes enpo164tlyaj11_ts__ts {
      0% {
        transform: translate(70.2683px, 8.46369px) scale(0, 0);
      }
      42.682927% {
        transform: translate(70.2683px, 8.46369px) scale(0, 0);
      }
      54.878049% {
        transform: translate(70.2683px, 8.46369px) scale(1, 1);
      }
      100% {
        transform: translate(70.2683px, 8.46369px) scale(1, 1);
      }
    }

    .enpo164tlyaj12_ts {
      animation: enpo164tlyaj12_ts__ts 1640ms linear infinite alternate forwards;
    }

    @keyframes enpo164tlyaj12_ts__ts {
      0% {
        transform: translate(34.968589px, 25.180027px) rotate(167.999987deg)
          scale(0, 0);
      }
      6.097561% {
        transform: translate(34.968589px, 25.180027px) rotate(167.999987deg)
          scale(0, 0);
      }
      18.292683% {
        transform: translate(34.968589px, 25.180027px) rotate(167.999987deg)
          scale(1, 1);
      }
      100% {
        transform: translate(34.968589px, 25.180027px) rotate(167.999987deg)
          scale(1, 1);
      }
    }

    .enpo164tlyaj13_ts {
      animation: enpo164tlyaj13_ts__ts 1640ms linear infinite alternate forwards;
    }

    @keyframes enpo164tlyaj13_ts__ts {
      0% {
        transform: translate(29.577764px, 26.325953px) rotate(167.999987deg)
          scale(0, 0);
      }
      18.292683% {
        transform: translate(29.577764px, 26.325953px) rotate(167.999987deg)
          scale(0, 0);
      }
      30.487805% {
        transform: translate(29.577764px, 26.325953px) rotate(167.999987deg)
          scale(1, 1);
      }
      100% {
        transform: translate(29.577764px, 26.325953px) rotate(167.999987deg)
          scale(1, 1);
      }
    }

    .enpo164tlyaj14_ts {
      animation: enpo164tlyaj14_ts__ts 1640ms linear infinite alternate forwards;
    }

    @keyframes enpo164tlyaj14_ts__ts {
      0% {
        transform: translate(18.770919px, 28.622886px) rotate(167.999987deg)
          scale(0, 0);
      }
      42.682927% {
        transform: translate(18.770919px, 28.622886px) rotate(167.999987deg)
          scale(0, 0);
      }
      54.878049% {
        transform: translate(18.770919px, 28.622886px) rotate(167.999987deg)
          scale(1, 1);
      }
      100% {
        transform: translate(18.770919px, 28.622886px) rotate(167.999987deg)
          scale(1, 1);
      }
    }

    .enpo164tlyaj15_ts {
      animation: enpo164tlyaj15_ts__ts 1640ms linear infinite alternate forwards;
    }

    @keyframes enpo164tlyaj15_ts__ts {
      0% {
        transform: translate(24.186917px, 27.471681px) rotate(167.999987deg)
          scale(0, 0);
      }
      30.487805% {
        transform: translate(24.186917px, 27.471681px) rotate(167.999987deg)
          scale(0, 0);
      }
      42.682927% {
        transform: translate(24.186917px, 27.471681px) rotate(167.999987deg)
          scale(1, 1);
      }
      100% {
        transform: translate(24.186917px, 27.471681px) rotate(167.999987deg)
          scale(1, 1);
      }
    }

    .enpo164tlyaj16_ts {
      animation: enpo164tlyaj16_ts__ts 1640ms linear infinite alternate forwards;
    }

    @keyframes enpo164tlyaj16_ts__ts {
      0% {
        transform: translate(39.140015px, 31.732968px) rotate(120.000012deg)
          skewX(0deg) skewY(0deg) scale(0, 0);
      }
      6.097561% {
        transform: translate(39.140015px, 31.732968px) rotate(120.000012deg)
          skewX(0deg) skewY(0deg) scale(0, 0);
      }
      18.292683% {
        transform: translate(39.140015px, 31.732968px) rotate(120.000012deg)
          skewX(0deg) skewY(0deg) scale(1, 1);
      }
      100% {
        transform: translate(39.140015px, 31.732968px) rotate(120.000012deg)
          skewX(0deg) skewY(0deg) scale(1, 1);
      }
    }

    .enpo164tlyaj17_ts {
      animation: enpo164tlyaj17_ts__ts 1640ms linear infinite alternate forwards;
    }

    @keyframes enpo164tlyaj17_ts__ts {
      0% {
        transform: translate(36.384415px, 36.505813px) rotate(120.000012deg)
          skewX(0deg) skewY(0deg) scale(0, 0);
      }
      18.292683% {
        transform: translate(36.384415px, 36.505813px) rotate(120.000012deg)
          skewX(0deg) skewY(0deg) scale(0, 0);
      }
      30.487805% {
        transform: translate(36.384415px, 36.505813px) rotate(120.000012deg)
          skewX(0deg) skewY(0deg) scale(1, 1);
      }
      100% {
        transform: translate(36.384415px, 36.505813px) rotate(120.000012deg)
          skewX(0deg) skewY(0deg) scale(1, 1);
      }
    }

    .enpo164tlyaj18_ts {
      animation: enpo164tlyaj18_ts__ts 1640ms linear infinite alternate forwards;
    }

    @keyframes enpo164tlyaj18_ts__ts {
      0% {
        transform: translate(33.628764px, 41.278645px) rotate(120.000012deg)
          skewX(0deg) skewY(0deg) scale(0, 0);
      }
      30.487805% {
        transform: translate(33.628764px, 41.278645px) rotate(120.000012deg)
          skewX(0deg) skewY(0deg) scale(0, 0);
      }
      42.682927% {
        transform: translate(33.628764px, 41.278645px) rotate(120.000012deg)
          skewX(0deg) skewY(0deg) scale(1, 1);
      }
      100% {
        transform: translate(33.628764px, 41.278645px) rotate(120.000012deg)
          skewX(0deg) skewY(0deg) scale(1, 1);
      }
    }

    .enpo164tlyaj19_ts {
      animation: enpo164tlyaj19_ts__ts 1640ms linear infinite alternate forwards;
    }

    @keyframes enpo164tlyaj19_ts__ts {
      0% {
        transform: translate(53.342461px, 41.396211px) rotate(239.999988deg)
          skewX(-0deg) skewY(0deg) scale(0, 0);
      }
      30.487805% {
        transform: translate(53.342461px, 41.396211px) rotate(239.999988deg)
          skewX(-0deg) skewY(0deg) scale(0, 0);
      }
      42.682927% {
        transform: translate(53.342461px, 41.396211px) rotate(239.999988deg)
          skewX(-0deg) skewY(0deg) scale(1, 1);
      }
      100% {
        transform: translate(53.342461px, 41.396211px) rotate(239.999988deg)
          skewX(-0deg) skewY(0deg) scale(1, 1);
      }
    }

    .enpo164tlyaj20_ts {
      animation: enpo164tlyaj20_ts__ts 1640ms linear infinite alternate forwards;
    }

    @keyframes enpo164tlyaj20_ts__ts {
      0% {
        transform: translate(50.58681px, 36.62288px) rotate(239.999988deg)
          skewX(-0deg) skewY(0deg) scale(0, 0);
      }
      18.292683% {
        transform: translate(50.58681px, 36.62288px) rotate(239.999988deg)
          skewX(-0deg) skewY(0deg) scale(0, 0);
      }
      30.487805% {
        transform: translate(50.58681px, 36.62288px) rotate(239.999988deg)
          skewX(-0deg) skewY(0deg) scale(1, 1);
      }
      100% {
        transform: translate(50.58681px, 36.62288px) rotate(239.999988deg)
          skewX(-0deg) skewY(0deg) scale(1, 1);
      }
    }

    .enpo164tlyaj21_ts {
      animation: enpo164tlyaj21_ts__ts 1640ms linear infinite alternate forwards;
    }

    @keyframes enpo164tlyaj21_ts__ts {
      0% {
        transform: translate(47.831296px, 31.849985px) rotate(239.999988deg)
          skewX(-0deg) skewY(0deg) scale(0, 0);
      }
      6.097561% {
        transform: translate(47.831296px, 31.849985px) rotate(239.999988deg)
          skewX(-0deg) skewY(0deg) scale(0, 0);
      }
      18.292683% {
        transform: translate(47.831296px, 31.849985px) rotate(239.999988deg)
          skewX(-0deg) skewY(0deg) scale(1, 1);
      }
      100% {
        transform: translate(47.831296px, 31.849985px) rotate(239.999988deg)
          skewX(-0deg) skewY(0deg) scale(1, 1);
      }
    }

    .enpo164tlyaj22_ts {
      animation: enpo164tlyaj22_ts__ts 1640ms linear infinite alternate forwards;
    }

    @keyframes enpo164tlyaj22_ts__ts {
      0% {
        transform: translate(50.948455px, 18.94596px) rotate(-30.000012deg)
          skewX(-0deg) skewY(0deg) scale(0, 0);
      }
      6.097561% {
        transform: translate(50.948455px, 18.94596px) rotate(-30.000012deg)
          skewX(-0deg) skewY(0deg) scale(0, 0);
      }
      18.292683% {
        transform: translate(50.948455px, 18.94596px) rotate(-30.000012deg)
          skewX(-0deg) skewY(0deg) scale(1, 1);
      }
      100% {
        transform: translate(50.948455px, 18.94596px) rotate(-30.000012deg)
          skewX(-0deg) skewY(0deg) scale(1, 1);
      }
    }

    .enpo164tlyaj23_ts {
      animation: enpo164tlyaj23_ts__ts 1640ms linear infinite alternate forwards;
    }

    @keyframes enpo164tlyaj23_ts__ts {
      0% {
        transform: translate(55.721273px, 16.190441px) rotate(-30.000012deg)
          skewX(-0deg) skewY(0deg) scale(0, 0);
      }
      18.292683% {
        transform: translate(55.721273px, 16.190441px) rotate(-30.000012deg)
          skewX(-0deg) skewY(0deg) scale(0, 0);
      }
      30.487805% {
        transform: translate(55.721273px, 16.190441px) rotate(-30.000012deg)
          skewX(-0deg) skewY(0deg) scale(1, 1);
      }
      100% {
        transform: translate(55.721273px, 16.190441px) rotate(-30.000012deg)
          skewX(-0deg) skewY(0deg) scale(1, 1);
      }
    }

    .enpo164tlyaj24_ts {
      animation: enpo164tlyaj24_ts__ts 1640ms linear infinite alternate forwards;
    }

    @keyframes enpo164tlyaj24_ts__ts {
      0% {
        transform: translate(60.49418px, 13.434773px) rotate(-30.000012deg)
          skewX(-0deg) skewY(0deg) scale(0, 0);
      }
      30.487805% {
        transform: translate(60.49418px, 13.434773px) rotate(-30.000012deg)
          skewX(-0deg) skewY(0deg) scale(0, 0);
      }
      42.682927% {
        transform: translate(60.49418px, 13.434773px) rotate(-30.000012deg)
          skewX(-0deg) skewY(0deg) scale(1, 1);
      }
      100% {
        transform: translate(60.49418px, 13.434773px) rotate(-30.000012deg)
          skewX(-0deg) skewY(0deg) scale(1, 1);
      }
    }
  }
}
