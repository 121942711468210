@import "src/assets/stylesheets/mixins";

.switch {
  @include themify($themes) {
    :global(.bp3-control-indicator) {
      outline: none !important;
    }
    input:checked ~ :global(.bp3-control-indicator) {
      background: themed("switchOffBackgroundColor") !important;
      &:before {
        background: themed("tintDarkerColor") !important;
        box-shadow: none;
      }
    }
  }
}
