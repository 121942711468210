@import "src/assets/stylesheets/mixins";

.toolbar {
  text-align: left;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  @include themify($themes) {
    border-bottom: 1px solid themed('blocksBorderColor');

    :global(.bp3-popover-target) {
      height: 100%;
    }
    .button {
      height: 100%;
      border-radius: 0;
      width: 50px;
    }
  }
}
