@import "src/assets/stylesheets/mixins";

.sideBar {
  @include themify($themes) {
    position: absolute;
    right: 0;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 30px;

    .tabs {
      margin-top: 300px;
      transform: rotate(-90deg);
      .tab {
        text-transform: uppercase;
        padding: 8px 16px;
        margin: 0 5px;
      }
    }
  }
}
