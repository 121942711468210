@import "src/assets/stylesheets/mixins";

.organization {
  display: flex;
  flex-direction: column;
  @include themify($themes) {
    .wrapper {
      flex: 1;
      display: flex;
      justify-content: center;
      .container {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-width: 1200px;
        .content {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
        }
        .footer {
          max-width: 1200px;
          margin-top: 20px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
