@import "src/assets/stylesheets/mixins";

.sideBarTitle {
  @include themify($themes) {
    &:not(.primary) {
      cursor: pointer;
      background: themed('sectionTitleBackgroundColor');
    }
    text-align: left;
    border-bottom: 1px solid themed('blocksBorderColor');
    padding: 14px 10px;
    font-size: 13px;
    font-weight: bold;
  }

  .caret {
    transition: all 0.2s ease-in-out;
    margin-right: 5px;

    &.open {
      transform: rotate(90deg);
    }
  }
}
