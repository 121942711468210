.tagContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  z-index: 10;
  padding: 5px;

  >* {
    margin-left: 10px;
  }
}