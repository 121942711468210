.auto-complete {
  flex: 1;
  position: relative;

  .popover {
    width: 100%;
  }

  .bp3-popover-target {
    width: 100%;
  }

  .list {
    padding: 0;
  }
}
