@import "src/assets/stylesheets/mixins";

.toolbarContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  .toolbar {
    @include themify($themes) {
      display: flex;
      justify-content: flex-end;
      padding: 10px;

      &.open {
        background: themed('mainBackgroundColor');
        border-bottom: 1px solid themed('blocksBorderColor');
      }

      .button:disabled {
        pointer-events: none;
      }
    }
  }
}
