@import "src/assets/stylesheets/mixins";

.entryLine {
  @include themify($themes) {
    display: flex;
    padding: 5px 0;
    .actions {
      flex: 1;
      flex-basis: 80px;
      display: flex;
      align-items: center;

      &.empty {
        flex: 0;
      };

      .remove {
      }

      .add {

      }
    }
  }
}
