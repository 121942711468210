@import "src/assets/stylesheets/mixins";

.paginatedList {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  @include themify($themes) {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    .navControls {
      display: flex;
      justify-content: space-between;
      margin: 10px 80px;
    }

    .navButton {
      outline: none;
    }
  }
}
