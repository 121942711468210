@import "src/assets/stylesheets/mixins";
@import "src/assets/stylesheets/animations";

.queryToolbar {
  display: flex;
  flex-direction: column;
  min-height: 41px;
  .actionsWrapper {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    .actions {
      justify-content: flex-end;
      display: flex;
      flex: 3;
    }
  }
  .tabs {
    display: flex;
    flex: 7;
  }

  @include themify($themes) {
  }

  .format {
    animation: fade-in 0.3s ease-in-out;
    font-size: 12px;
    padding: 8px 6px;
  }
}
