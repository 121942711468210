@import "src/assets/stylesheets/variables";
@import "src/assets/stylesheets/mixins";

.appHeader {
  position: sticky;
  top: 0;
  z-index: 10;
  @include themify($themes) {
    padding: 10px;
    text-align: left;
    overflow: hidden;
    display: flex;
    align-items: center;
    background: themed("mainBackgroundColor");
    border-bottom: 1px solid themed("blocksBorderColor");
    min-height: 65px;
    .attribution {
      display: flex;
      align-items: center;
      font-size: 10px;
      margin-left: 10px;
      img, svg {
        margin-left: 10px;
      }
    }
    .logoContainer {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 40px;
    }
    .headerContent {
      display: flex;
      align-items: center;
      flex: 1;
      padding: 0 5px;
      .logo {
        margin-right: 10px;
        width: 128px;
      }

      .logoLink {
        height: 40px;
      }
    }
  }
}
