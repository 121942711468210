@import "src/assets/stylesheets/mixins";

.gremlifyIcon {
  g {
    //transform: scale(0.75);
  }
  line, path {
  }
  circle {
  }
}

.tinkerPopIcon {
  @include themify($themes) {
    fill: themed('tintColor');
  }
}

.chalkArrow {
}

.mouse {
  @include themify($themes) {
    .active {
      fill: themed('textColor');
    }

    .outline, .cable {
      stroke: themed('textColor');
    }

    line {
      stroke: themed('textColor');
    }
  }
}
