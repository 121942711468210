@import "src/assets/stylesheets/mixins";

.creationDialog {
  padding-bottom: 0;
  @include themify($themes) {
    min-width: 660px;
    min-height: 360px;
    color: themed('textColor');
    .dialogContent {
      display: flex;
      flex-direction: column;

      .spinnerWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
      }

      flex: 1;
      padding: 10px;
      form {
        flex: 1;
        display: flex;

        .editor {
          flex: 1;
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          button {
            color: themed('textColor');
            &:disabled {
              opacity: 0.4;
            }
          }
        }

        .footer {
          margin-top: 5px;
          flex: 0;
        }
      }

      .entries {
        @include styled-scrollbar;
        //flex: 1;
        height: 270px;
        overflow-y: auto;
      }

      .callOut {
        margin: 10px 0;
      }

      .reuse {
        padding-top: 10px;
      }
    }
  }
}
