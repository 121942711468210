@import "src/assets/stylesheets/mixins";

.htmlSelect {
  @include themify($themes) {
    display: flex;
    align-items: center;
    select {
      background: themed('inputBackgroundColor');
      color: themed('textColor');
      height: 100%;
      outline: none;
    }

    :global(.bp3-icon-double-caret-vertical) {
      top: unset;
    }
  }
}
