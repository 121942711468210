.createOrganization {
  min-width: 820px;
  .content {
    padding: 20px;

    .footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}
