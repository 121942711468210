@import "src/assets/stylesheets/mixins";

.gyFormGroup {
  label {
    font-size: 10px;
    text-align: left;
  }

  @include themify($themes) {
    textarea, input {
      color: themed('textColor');
      background: themed('inputBackgroundColor');
    }
    textarea:focus, input:focus {
      box-shadow: inset 0 0 2px themed('focusedInputInsetColor');
      outline: none;
    }
  }
}
