@import "src/assets/stylesheets/mixins";

.react-joyride__tooltip {
  @include themifyFlat($themes) {
    background: themed("mainBackgroundColor") !important;
    color: themed("textColor") !important;
    font-family: "Open Sans", serif !important;
    font-weight: 300;

    button {
      outline: none;
    }
    button[data-action="primary"] {
      background: themed("inputBackgroundColor") !important;
      padding: 10px 24px !important;

      &:hover {
        color: themed("tintColor") !important;
      }
    }

    button[data-action="back"] {
      color: themed("textColor") !important;
      &:hover {
        color: themed("tintColor") !important;
      }
    }

    button[data-action="close"] {
      stroke: themed("textColor") !important;
    }
  }
}

.__floater__arrow {
  @include themifyFlat($themes) {
    svg polygon {
      fill: themed("mainBackgroundColor") !important;
    }
  }
}
