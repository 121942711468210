@import "src/assets/stylesheets/mixins";

.metadataDisplay {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: 150px;

  .sep {
    margin: 0 5px;
  }
  .input {
    input {
      background: none;
      font-size: 12px;
      padding: 0;
      border: none;
      &:focus {
        box-shadow: none !important;
        padding: 0 !important;
      }
    }
  }
  @include themify($themes) {
    font-size: 12px;
    .username {
      color: themed('tintColor');
    }
  }
}
