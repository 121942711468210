@import "src/assets/stylesheets/mixins";

ul.instructions {
  @include themify($themes) {
    background: themed('mainBackgroundColorA');
    padding: 0;
    list-style-type: none;
    border-bottom: 1px solid themed('blocksBorderColor');
    margin: 0;
    >li {
      user-select: none;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      //border-bottom: 1px solid themed('blocksBorderColor');
    }

    .keys {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 2;
    }

    .key {
      background: themed('mainBackgroundColor');
      border-bottom: 4px solid themed('tintColor');
      border-left: 1px solid themed('blocksBorderColor');
      border-right: 1px solid themed('blocksBorderColor');
      border-top: 1px solid themed('blocksBorderColor');
      color: themed('textColor');
    }

    .description {
      text-align: left;
      flex: 9;
    }

    .plus {
      margin: 0 5px;
    }
  }
}
