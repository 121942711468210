@import "src/assets/stylesheets/mixins";

.organizationData {
  @include themify($themes) {
    flex: 1;
    display: flex;
    justify-content: center;
    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .actionButton {
        padding: 0;
        min-width: unset;
        min-height: unset;
      }

      .name {
        display: flex;
        align-items: center;
        .nameText {
          margin-left: 10px;
        }
      }
      .table {
        :global(.tr:nth-child(2n+1) .td) {
          background: themed('tableOddRowColor');
        }

        .status {
          cursor: pointer;
        }
      }
    }
  }
}
