@import "src/assets/stylesheets/variables";
@import "src/assets/stylesheets/mixins";

.view-wrapper {
  cursor: crosshair;

  .edge {
    &.selected {
      .edge-text {
        @include themifyFlat($themes) {
          fill: themed('tintColor');
        }
      }
    }
    .edge-text {
      @include themifyFlat($themes) {
        @include text-shadow(themed('blocksBorderColor'));
      }
    }
  }
}
