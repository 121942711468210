@import "src/assets/stylesheets/mixins";
.dataViewer {
  @include themify($themes) {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;

    .entry {
      outline: none;
      max-width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      word-break: break-all;
      margin-right: 10px;
      label {
        font-weight: bold;
        color: themed('tintColor');
        margin-right: 5px;
      }
    }
  }
}
