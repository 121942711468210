@import "src/assets/stylesheets/variables";
@import "src/assets/stylesheets/mixins";

.results {
  height: calc(100% - 50px);
  position: absolute;
  width: 100%;
  @include themify($themes) {
    @include json-view-with-intent('tintColor');

    &.hasError {
      @include json-view-with-intent('danger');
    }

    display: flex;
    overflow: auto;
    @include styled-scrollbar;
    white-space: nowrap;
    :global(.bp3-non-ideal-state) {
      padding-top: 20px;
      justify-content: flex-start;
    }
    .error {
      color: themed("danger");
    }
    .toolbar {
      padding: 5px;
      z-index: 9;
      position: sticky;
      top: 0;
    }
    .json {
      position: absolute;
    }
  }
}
