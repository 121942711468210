.workspaces {
  overflow: auto;

  .breadCrumb {
    padding: 0;
  }
  .searchWrapper {
    padding: 20px 0;
    display: flex;
  }
  .wrapper {
    display: flex;
    align-items: flex-start;
    .content {
      flex: 1;
      overflow: auto;
      margin: 50px;
      margin-top: 60px;
      .title {
        text-align: left;
        margin: 0;
      }

      .list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;
      }
    }
  }
}
