@import "src/assets/stylesheets/mixins";

.typeTag {
  display: flex;
  justify-content: center;

  @include themify($themes) {
    &.string {
      background-color: themed('booleanTypeColor');
    }

    &.boolean {
      background-color: themed('stringTypeColor');
    }

    &.number {
      background-color: themed('numberTypeColor');
    }
  }

  span {
    display: flex;
    justify-content: center;
  }
}
