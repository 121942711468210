.labelItem {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  .propSelect {
    flex: 1;
    select {
      padding: 5px;
    }
  }
  .label {
    display: flex;
    align-items: center;
    flex: 1;
    text-align: left;
    margin-right: 5px;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
