@import "src/assets/stylesheets/mixins";

.themes {
  @include themify($themes) {
    .themeList {
      list-style-type: none;
      padding: 0;
      margin: 0;
      .themeItem {
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        .circleSvg {
          width: 18px;
          height: 18px;
          margin-right: 16px;
          circle {
            transition: all 0.8s ease;
          }
        }

        .themeName {
          margin-right: 10px;
        }

        &:hover {
          cursor: pointer;
          color: themed('sideBarItemMainColor');
          circle {
            transform: scale(2);
          }
        }
      }
    }
  }
}
