.filters {
  margin-top: 20px;
  .title {
    text-align: left;
    margin-bottom: 20px;
    margin-left: 20px;
    font-size: 11px;
  }

  .list {
    list-style: none;
    margin: 0 0 0 20px;
    padding: 0;
    .listItem {
      margin-bottom: 10px;
      .userItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }

        .userName {
          display: flex;
          align-items: center;
        }

        .avatar {
          margin-right: 10px;
        }
      }
    }
  }
}
