@import "./../../assets/stylesheets/mixins";
@import "./../../assets/stylesheets/variables";

div.content {
  @include themify($themes) {
    position: relative;
    flex: 1;
    display: flex;

    /** TODO:move all of these to the wrapper **/
    :global(.reflex-element) {
      background: themed('mainBackgroundColor');
    }
    :global(.reflex-container.horizontal) > :global(.reflex-splitter) {
      border-bottom: 0;
      border-top: 1px solid themed('blocksBorderColor');;
      height: 1px;
      z-index: 10;
    }

    :global(.reflex-container.vertical) > :global(.reflex-splitter) {
      background-color: themed('blocksBorderColor');;
      border-left: 0;
      border-right: 1px  solid themed('blocksBorderColor');
      width: 1px;
      z-index: 10;

    }

    .sideBar {
      flex: 0.125;
      min-width: 200px;
      overflow: hidden;
    }
  }
}
