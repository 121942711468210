@import "../../assets/stylesheets/mixins";

.button {
  outline: none;
  @include themify($themes) {
    &:global(.bp3-button) {
      background: themed("buttonBackgroundColor");
    }

    &:global(.bp3-intent-primary) {
      background: themed("primaryButtonBackgroundColor");
    }

    &:global(.bp3-intent-danger) {
      background: themed("dangerButtonBackgroundColor");
    }

    &:global(.bp3-intent-success) {
      background: themed("tintColor");
    }
    &:global(.bp3-intent-success):hover {
      background: themed("tintDarkerColor");
    }
  }
  &:hover:not(:disabled):not(:global(.bp3-intent-success)):not(:global(.bp3-intent-danger)) {
    @include themify($themes) {
      color: themed("tintColor");
      svg {
        fill: themed("tintColor");
      }
    }
  }
}

.active {
  @include themify($themes) {
    background: none;
    svg {
      fill: themed("tintColor");
    }
    color: themed("tintColor") !important;
  }
}
