@import "src/assets/stylesheets/mixins";

.queryTabs {
  display: flex;
  overflow-x: auto;
  @include themify($themes) {
    .tabList {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;

      li:last-child {
        .tab {
          border-top-right-radius: 5px;
        }
      }
      .tab {
        padding: 8px 6px;
        text-align: left;
        cursor: pointer;
        border-top: 1px solid themed('blocksBorderColor');
        border-right: 1px solid themed('blocksBorderColor');
        border-bottom: 2px solid transparent;
        &.selected {
          border-bottom: 2px solid themed('tintColor');
          .tabText {
            cursor: auto;
          }
        }

        .inner {
          &:hover {
            .remove {
              &.removable {
                visibility: visible;
              }
            }
          }
          display: flex;
          justify-content: space-between;
          align-items: center;
          .remove {
            visibility: hidden;
          }
          .tabText {
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            max-width: inherit;
            cursor: pointer;
          }
        }
      }
    }
  }
}
