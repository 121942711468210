@import "src/assets/stylesheets/mixins";
@import "src/assets/stylesheets/animations";

.templates {
  position: relative;
  flex: 1;
  display: flex;

  $height: 500px;
  .templatesWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .listWrapper {
    flex: 1;
  }
  @include themify($themes) {
    .flipScene {
      height: $height;
    }
    .confirmation {
      animation: fade-in 0.3s ease-in-out;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      backdrop-filter: blur(2px);
      background: themed("mainBackgroundColorA");
      width: 100%;
      height: 100%;
      position: absolute;
      padding: 5px;
      .buttonGroup {
        width: 100%;
        padding: 0 20px;
        .button {
          //background: none;
        }
      }
      .confirmationContent {
        padding-top: 100px;
        justify-content: flex-start;
      }
    }
  }

  .spinnerWrapper {
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    animation: fade-in 0.3s ease-in-out;
    @include themify($themes) {
      backdrop-filter: blur(2px);
      background: themed("mainBackgroundColorA");
    }

    width: 100%;
    height: 100%;
  }
  @include themify($themes) {
    .description {
      text-align: left;
      line-height: 24px;
    }
    .select {
      width: 100%;
    }

    .templateList {
      li {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    .templateCard {
      padding: 15px;
      height: 100px;

      &:hover {
        color: themed("textColor");
        h3 {
          color: themed("textColor");
        }
        background: themed("tintColor");
      }

      h3 {
        margin: 0;
        text-align: left;
        color: themed("tintColor");
      }
      p {
        text-align: left;
        padding: 5px 0;
        font-size: 12px;
      }
    }
  }
}
