@import "src/assets/stylesheets/mixins";
.headerActions {
  @include themify($themes) {
    display: flex;
    flex: 1;
    margin-left: 10px;

    .user {
      display: flex;
      align-items: center;
    }
    .menu {
      margin-left: 10px;

      button {
        min-width: 60px;
        min-height: 30px;
        margin-right: 30px;
        span {
          font-size: 14px;
        }
      }
    }
  }
}
