@import "src/assets/stylesheets/variables";
@import "src/assets/stylesheets/mixins";
@import "src/assets/stylesheets/animations";

.graphBuilder {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  .nodeEditorContainer {
    flex: 1;
    flex-basis: 200px;
  }

  .splitterHorizontal {
    border-bottom: 0 !important;
    border-top: 1px solid #000000 !important;
    height: 1px !important;
  }

  .graphWrapper {
    height: 100%;
  }

  @include themify($themes) {
    :global(.view-wrapper) svg {
      background: themed('graphBackgroundColor');
    }

    .pushDown {
      transform: translateY(55px);
    }

    .toolbar {
      transition: transform 0.3s ease;
    }
  }

  .tag {
    span {
      color: $blue-gray2;
    }
  }
}


