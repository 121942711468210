@import "src/assets/stylesheets/mixins";

.main {
  @include themify($themes) {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    background: themed('mainBackgroundColor');
  }

  .fallback {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
