@import "src/assets/stylesheets/mixins";

.keyValueEditor {
  @include themify($themes) {
    flex: 1;
    .actions {
      margin-left: auto;
    }

    .errors {
      color: themed('danger');
    }
    .footer {
      padding: 10px 20px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid themed('blocksBorderColor');
    }
  }
}
