@import "src/assets/stylesheets/mixins";
@import "src/assets/stylesheets/animations";

.firstInstructions {
  @include themify($themes) {
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 10;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0;
    p {
      color: themed("altTextColor");
      font-family: "Gloria Hallelujah", cursive;
      font-weight: 300;
      line-height: 44px;

      &.start {
        font-size: 34px;
      }
      &.keyboard {
        font-size: 26px;
        margin-bottom: 20px;
      }
    }

    .arrowWrapper {
      svg g {
        stroke: themed("altTextColor");
      }
      animation: float 3s ease-in-out infinite;
    }
  }
}
