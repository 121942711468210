.schemaBuilder {
  flex: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .graph {
    height: 100%;
    display: flex;
  }
}
