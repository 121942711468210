@import "src/assets/stylesheets/mixins";

.exportDialog {
  @include themify($themes) {
    min-width: 660px;
    min-height: 360px;

    .footer {
      padding: 10px;
      display: flex;
      justify-content: flex-end;
      .copy {
        display: none;
      }
    }
    .content {
      flex: 1;
      display: flex;

      .query {
        background: themed('inputBackgroundColor');
        width: 100%;
        max-height: 330px;
        overflow: auto;
        color: themed('textColor');
        font-size: 16px;
        margin: 0;
      }
    }
  }
}
