@import "src/assets/stylesheets/variables";
@import "src/assets/stylesheets/mixins";

.alert:global(.bp3-alert) {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  @include themify($themes) {
    background: themed('mainBackgroundColor');
    color: themed('textColor') !important;

    button {
      outline: none;
      background: themed('buttonBackgroundColor');
      color: themed('textColor');
      border: none;

      &:hover {
        color: themed('tintColor');
      }
      &:not(:global(.bp3-intent-danger)) {
        box-shadow: none;
      }
    }
  }
}
