@import "variables";
@import "theme";

@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {
    :global(.theme-#{$theme}) & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@mixin themifyFlat($themes: $themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@mixin text-shadow($color) {
  text-shadow: -1px -1px 0px $color, 0px -1px 0px $color,
  1px -1px 0px $color, -1px 0px 0px $color, 1px 0px 0px $color,
  -1px 1px 0px $color, 0px 1px 0px $color, 1px 1px 0px $color,
  -2px -2px 0px $color, -1px -2px 0px $color, 0px -2px 0px $color,
  1px -2px 0px $color, 2px -2px 0px $color, 2px -1px 0px $color,
  2px 0px 0px $color, 2px 1px 0px $color, 2px 2px 0px $color,
  1px 2px 0px $color, 0px 2px 0px $color, -1px 2px 0px $color,
  -2px 2px 0px $color, -2px 1px 0px $color, -2px 0px 0px $color,
  -2px -1px 0px $color;;
}

@mixin styled-scrollbar($dim: 8px) {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: $dim;
    height: $dim;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: $dim * 2;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-corner { background: transparent; }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

@mixin json-view-with-intent($intentColor) {
  > :global(.react-json-view) {
    background: themed("mainBackgroundColor") !important;

    :global(.copy-icon),
    :global(.collapsed-icon),
    :global(.expanded-icon) {
      svg {
        color: themed($intentColor) !important;
      }
    }
    :global(.variable-value) {
      > div {
        color: themed($intentColor) !important;
      }
    }
    :global(.string-value),
    :global(.variable-value),
    :global(.node-ellipsis) {
      color: themed($intentColor) !important;
    }
  }
}
