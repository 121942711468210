@import "src/assets/stylesheets/variables";
@import "src/assets/stylesheets/mixins";

.favouriteQueries {
  padding: 10px;

  .spinnerWrapper {
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    animation: fade-in 0.3s ease-in-out;
    @include themify($themes) {
      backdrop-filter: blur(2px);
      background: themed("mainBackgroundColorA");
    }

    width: 100%;
    height: 100%;
  }

  @include themify($themes) {
    text-align: left;
    z-index: 11;
    .favouriteQueriesList {
      max-height: 650px;
      overflow-y: auto;
      padding: 0;
      list-style-type: none;

      li {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      .queryItem {
        cursor: pointer;
        margin-bottom: 10px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .actions {
          display: none;
          position: absolute;
          right: 0;
          padding: 5px;

          .actionButton {
            min-width: unset;
            min-height: unset;
          }
        }

        &:hover {
          .actions {
            display: block;
          }
        }

        .query {
          flex: 1;
          white-space: pre-wrap;
          font-family: Courier, serif;
          margin: 0;
          font-size: 12px;

          &:hover {
            color: themed('tintColor');
          }
        }
      }
    }
  }
}
