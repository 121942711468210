@import "blueprint";
@import "mixins";

@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Biryani:wght@200;400&display=swap');

p {
  line-height: 20px;
}

div#root {
  height: 100%;
}

* {
  @include styled-scrollbar;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

/** OVERRIDES! **/
button.bp3-button {
  &.bp3-minimal {
    background: none !important;
  }

  .bp3-icon {
    color: inherit !important;
  }
}
