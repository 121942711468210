@import "src/assets/stylesheets/mixins";

.propList {
  @include themify($themes) {
    border-bottom: 1px solid themed("blocksBorderColor");
    .empty {
      padding: 10px;
      text-align: left;
    }
    .collapseTrigger {
      display: flex;
      flex: 1;
    }
    .list {
      list-style-type: none;
      padding: 5px 10px 15px;
      max-height: 350px;
      overflow: auto;
      @include styled-scrollbar(8px);
      li {
        padding: 5px 0;

        &.tableTitles {
          display: flex;
          justify-content: space-between;
          padding: 5px;
          > div {
            flex: 1;
            text-align: left;
            border-bottom: 1px solid themed("blocksBorderColor");
            padding: 5px 0;
          }
        }
      }
    }

    .title {
      display: flex;
      align-items: center;
      border-bottom: 1px solid themed("blocksBorderColor");
      padding: 10px;
      h3 {
        color: themed("tintColor");
        margin: 0;
      }
      text-align: left;
    }
  }
}
