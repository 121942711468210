@import "src/assets/stylesheets/mixins";

.footer {
  @include themify($themes) {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
    padding: 10px;
    background: themed("workspaceCardFooter");
    .user {
      display: flex;
      align-items: center;
      .avatar {
        margin-right: 5px;
      }
    }
    .titleWrapper {
      display: flex;
      justify-content: space-between;
      flex: 1;
      .title {
        color: themed("tintColor");
        margin: 5px 0;
      }

      .keyId {
        color: themed('textColor');
      }

      .description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      text-align: left;
      .privacyWrapper {
        display: flex;
        align-items: center;
      }
    }

    .infoWrapper {
      display: flex;
      justify-content: space-between;
      padding: 10px 0 5px 0;
      font-size: 10px;
      align-items: center;
    }
  }
}
