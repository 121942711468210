@import "src/assets/stylesheets/mixins";

.generalToolbar {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;

  @include themify($themes) {
    .content {
    }
    .help {
      pointer-events: none;
    }
  }
}
