@import "src/assets/stylesheets/mixins";

.invitation {
  @include themify($themes) {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      .logo {
        margin: 10px 0;
      }
      .card {
        strong {
          color: themed("tintColor");
        }
        width: 400px;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .button {
          margin: 10px 0;
        }
      }
    }
  }
}
