@import "src/assets/stylesheets/mixins";
@import "src/assets/stylesheets/animations";

.siteSideMenu {
  @include themify($themes) {
    display: flex;
    background-color: themed("mainBackgroundColor");
    height: 100%;
    .content {
      width: 250px;
      display: flex;
      flex-direction: column;
    }
    &.open {
      border-right: 1px solid themed('blocksBorderColor');
    }
    &:not(.open) {
      .iconsBar {
        //box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
      }
    }

    .mainTitle {
      font-size: 13px;
    }

    :global(.scrollbar-container) {
      display: none;
    }
    &:hover {
      :global(.scrollbar-container) {
        display: block;
      }
    }

    .iconsBar {
      min-width: 30px;
      padding: 0 10px;
      border-right: 1px solid themed('blocksBorderColor');
      display: flex;
      flex-direction: column;
      .iconItem {
        margin: 10px 0;
        width: 32px;
        height: 32px;
        background: none;
        border: none;
        outline: none;
        padding: 0;
        & button,
        :global(.bp3-icon) {
          color: themed("sideBarItemMainColor");
        }
        &:not(:global(.bp3-icon)) {
          > svg {
            stroke: themed("sideBarItemMainColor");
            fill: themed("sideBarItemMainColor");
          }
        }

        &:hover {
          cursor: pointer;
        }

        &:active,
        &.active,
        &:hover {
          @include themify($themes) {
            & button,
            :global(.bp3-icon) {
              color: themed("tintColor");
            }
            &:not(:global(.bp3-icon)) {
              > svg {
                stroke: themed("tintColor");
                fill: themed("tintColor");
              }
            }
          }
        }
      }
    }
  }
}

.updates {
  @include themify($themes) {
    position: relative;
    .dotIcon {
      position: absolute;
      right: 3px;
      top: -3px;
      z-index: 10;
      svg {
        color: themed("updatesDotColor");
        animation: pop 0.4s ease;
      }
    }
  }
}
