@import "src/assets/stylesheets/variables";
@import "src/assets/stylesheets/animations";
@import "src/assets/stylesheets/mixins";

div.nodeEditor {
  @include themify($themes) {
    background: themed("toolbarBackgroundColor");
    border-bottom: 1px solid themed("blocksBorderColor");
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 100%;

    .error {
      background: $alizarin;
      padding: 5px;
    }

    .editor {
      //margin-top: 30px;
    }
    .entries {
      padding: 15px;
      max-height: 500px;
      overflow-y: auto;
    }
    .editorContainer {
      height: 100%;
    }
  }
}
