@import "src/assets/stylesheets/mixins";
@import "src/assets/stylesheets/animations";

.update {
  @include themify($themes) {
    white-space: pre-wrap;
    word-break: break-word;
    text-align: left;
    margin-bottom: 10px;
    background: themed('updateBackgroundColor');
    font-size: 12px;

    animation: pop 0.4s ease-in-out;
  }
}
