@import "src/assets/stylesheets/mixins";

.graphBuilderContextMenu {
  @include themify($themes) {
    background: themed("menuBackgroundColor");
    color: themed("contextMenuTextColor");
    :global(.bp3-icon) {
      color: themed("contextMenuTextColor");
    }

    :global(.bp3-menu) {
      background: themed("menuBackgroundColor") !important;
    }
  }
}

.menuHeading {
  padding-left: 5px;
}
