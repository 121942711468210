@import "src/assets/stylesheets/mixins";
@import "src/assets/stylesheets/animations";

.wrapper {
  display: flex;
  flex-direction: column;

  @include themify($themes) {
    z-index: 12;
    position: absolute;
    width: 100%;
    animation: slide-in-fade 0.3s ease-in-out;
    display: flex;

    .entityToolbar {
      width: 100%;
      .content {
        display: flex;
        .label,
        .actionButton {
          margin-right: 10px;

          &.active {
            color: themed('tintColor');
          }
        }

        .label {
          min-width: 100px;
          max-width: 250px;
          justify-content: center;
          text-align: center;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          text-overflow: ellipsis;

          .labelText {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .entityId {
            font-size: 10px;
          }
        }

        .buttons {
          display: flex;
        }

        .sep {
          display: flex;
          align-items: center;
          border-right: 1px solid themed("blocksBorderColor");
          padding: 10px 20px;
        }

        .dataViewerWrapper {
          overflow: hidden;
        }
      }
      background: themed("toolbarBackgroundColor");
      border-bottom: 1px solid themed("blocksBorderColor");
    }
  }
}
