@import "src/assets/stylesheets/mixins";

.gyTextarea {
  width: 100%;
}

.resizeable {}

.nonResizeable {
  resize: none;
}
