@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Biryani:wght@200;400&display=swap);
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
p {
  line-height: 20px; }

div#root {
  height: 100%; }

* {
  /* background of the scrollbar except button or resizer */
  /* scrollbar itself */
  /* set button(top and bottom of the scrollbar) */
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif; }
  *::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
    height: 8px; }
  *::-webkit-scrollbar-track {
    background: transparent; }
  *::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 16px; }
  *::-webkit-scrollbar-button {
    display: none; }
  *::-webkit-scrollbar-corner {
    background: transparent; }

/** OVERRIDES! **/
button.bp3-button.bp3-minimal {
  background: none !important; }

button.bp3-button .bp3-icon {
  color: inherit !important; }

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}
body {
  position: relative;
  overflow: hidden;
}
.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.theme-dark div.Mimic_mimic__1SaFo {
  flex: 1 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #1a1e22; }
  .theme-dark div.Mimic_mimic__1SaFo .Mimic_content__1vSkx {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    width: 100%; }
  .theme-dark div.Mimic_mimic__1SaFo .Mimic_ghostButton__3rdnX {
    visibility: hidden; }

.Header_appHeader__2meqE {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10; }
  .theme-dark .Header_appHeader__2meqE {
    padding: 10px;
    text-align: left;
    overflow: hidden;
    display: flex;
    align-items: center;
    background: #1a1e22;
    border-bottom: 1px solid #060809;
    min-height: 65px; }
    .theme-dark .Header_appHeader__2meqE .Header_attribution__2BRmn {
      display: flex;
      align-items: center;
      font-size: 10px;
      margin-left: 10px; }
      .theme-dark .Header_appHeader__2meqE .Header_attribution__2BRmn img, .theme-dark .Header_appHeader__2meqE .Header_attribution__2BRmn svg {
        margin-left: 10px; }
    .theme-dark .Header_appHeader__2meqE .Header_logoContainer__thx6v {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 40px; }
    .theme-dark .Header_appHeader__2meqE .Header_headerContent__1oilN {
      display: flex;
      align-items: center;
      flex: 1 1;
      padding: 0 5px; }
      .theme-dark .Header_appHeader__2meqE .Header_headerContent__1oilN .Header_logo__2gAbO {
        margin-right: 10px;
        width: 128px; }
      .theme-dark .Header_appHeader__2meqE .Header_headerContent__1oilN .Header_logoLink__18QRg {
        height: 40px; }

.theme-dark .Icon_tinkerPopIcon__1eWU1 {
  fill: #6ab04c; }

.theme-dark .Icon_mouse__H1JMB .Icon_active__3r2po {
  fill: #d3d3d3; }

.theme-dark .Icon_mouse__H1JMB .Icon_outline__16PZj, .theme-dark .Icon_mouse__H1JMB .Icon_cable__82B-s {
  stroke: #d3d3d3; }

.theme-dark .Icon_mouse__H1JMB line {
  stroke: #d3d3d3; }

@-webkit-keyframes Cloud_fade-in__1rM6g {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@keyframes Cloud_fade-in__1rM6g {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@-webkit-keyframes Cloud_pop__3t6N6 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes Cloud_pop__3t6N6 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes Cloud_float__3tdrE {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes Cloud_float__3tdrE {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes Cloud_slide-in-fade__2m8ov {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes Cloud_slide-in-fade__2m8ov {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.Cloud_cloudSpinner__2ocp- {
  -webkit-animation: Cloud_fade-in__1rM6g 0.4s ease-in;
          animation: Cloud_fade-in__1rM6g 0.4s ease-in;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .theme-dark .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- {
    stroke: white; }
  .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- .Cloud_enpo164tlyaj7_ts__2g--0 {
    -webkit-animation: Cloud_enpo164tlyaj7_ts__ts__18gjO 1640ms linear infinite alternate forwards;
            animation: Cloud_enpo164tlyaj7_ts__ts__18gjO 1640ms linear infinite alternate forwards; }

@-webkit-keyframes Cloud_enpo164tlyaj7_ts__ts__18gjO {
  0% {
    -webkit-transform: translate(8.46369px, 32.477px) scale(0, 0);
            transform: translate(8.46369px, 32.477px) scale(0, 0); }
  54.878049% {
    -webkit-transform: translate(8.46369px, 32.477px) scale(0, 0);
            transform: translate(8.46369px, 32.477px) scale(0, 0); }
  67.073171% {
    -webkit-transform: translate(8.46369px, 32.477px) scale(1, 1);
            transform: translate(8.46369px, 32.477px) scale(1, 1); }
  100% {
    -webkit-transform: translate(8.46369px, 32.477px) scale(1, 1);
            transform: translate(8.46369px, 32.477px) scale(1, 1); } }

@keyframes Cloud_enpo164tlyaj7_ts__ts__18gjO {
  0% {
    -webkit-transform: translate(8.46369px, 32.477px) scale(0, 0);
            transform: translate(8.46369px, 32.477px) scale(0, 0); }
  54.878049% {
    -webkit-transform: translate(8.46369px, 32.477px) scale(0, 0);
            transform: translate(8.46369px, 32.477px) scale(0, 0); }
  67.073171% {
    -webkit-transform: translate(8.46369px, 32.477px) scale(1, 1);
            transform: translate(8.46369px, 32.477px) scale(1, 1); }
  100% {
    -webkit-transform: translate(8.46369px, 32.477px) scale(1, 1);
            transform: translate(8.46369px, 32.477px) scale(1, 1); } }
  .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- .Cloud_enpo164tlyaj8_ts__2p6Zx {
    -webkit-animation: Cloud_enpo164tlyaj8_ts__ts__3mAPy 1640ms linear infinite alternate forwards;
            animation: Cloud_enpo164tlyaj8_ts__ts__3mAPy 1640ms linear infinite alternate forwards; }

@-webkit-keyframes Cloud_enpo164tlyaj8_ts__ts__3mAPy {
  0% {
    -webkit-transform: translate(29.1308px, 49.2075px) scale(0, 0);
            transform: translate(29.1308px, 49.2075px) scale(0, 0); }
  42.682927% {
    -webkit-transform: translate(29.1308px, 49.2075px) scale(0, 0);
            transform: translate(29.1308px, 49.2075px) scale(0, 0); }
  54.878049% {
    -webkit-transform: translate(29.1308px, 49.2075px) scale(1, 1);
            transform: translate(29.1308px, 49.2075px) scale(1, 1); }
  100% {
    -webkit-transform: translate(29.1308px, 49.2075px) scale(1, 1);
            transform: translate(29.1308px, 49.2075px) scale(1, 1); } }

@keyframes Cloud_enpo164tlyaj8_ts__ts__3mAPy {
  0% {
    -webkit-transform: translate(29.1308px, 49.2075px) scale(0, 0);
            transform: translate(29.1308px, 49.2075px) scale(0, 0); }
  42.682927% {
    -webkit-transform: translate(29.1308px, 49.2075px) scale(0, 0);
            transform: translate(29.1308px, 49.2075px) scale(0, 0); }
  54.878049% {
    -webkit-transform: translate(29.1308px, 49.2075px) scale(1, 1);
            transform: translate(29.1308px, 49.2075px) scale(1, 1); }
  100% {
    -webkit-transform: translate(29.1308px, 49.2075px) scale(1, 1);
            transform: translate(29.1308px, 49.2075px) scale(1, 1); } }
  .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- .Cloud_enpo164tlyaj9_ts__2VNQm {
    -webkit-animation: Cloud_enpo164tlyaj9_ts__ts__g33VH 1640ms linear infinite alternate forwards;
            animation: Cloud_enpo164tlyaj9_ts__ts__g33VH 1640ms linear infinite alternate forwards; }

@-webkit-keyframes Cloud_enpo164tlyaj9_ts__ts__g33VH {
  0% {
    -webkit-transform: translate(58.8522px, 50.979px) scale(0, 0);
            transform: translate(58.8522px, 50.979px) scale(0, 0); }
  42.682927% {
    -webkit-transform: translate(58.8522px, 50.979px) scale(0, 0);
            transform: translate(58.8522px, 50.979px) scale(0, 0); }
  54.878049% {
    -webkit-transform: translate(58.8522px, 50.979px) scale(1, 1);
            transform: translate(58.8522px, 50.979px) scale(1, 1); }
  100% {
    -webkit-transform: translate(58.8522px, 50.979px) scale(1, 1);
            transform: translate(58.8522px, 50.979px) scale(1, 1); } }

@keyframes Cloud_enpo164tlyaj9_ts__ts__g33VH {
  0% {
    -webkit-transform: translate(58.8522px, 50.979px) scale(0, 0);
            transform: translate(58.8522px, 50.979px) scale(0, 0); }
  42.682927% {
    -webkit-transform: translate(58.8522px, 50.979px) scale(0, 0);
            transform: translate(58.8522px, 50.979px) scale(0, 0); }
  54.878049% {
    -webkit-transform: translate(58.8522px, 50.979px) scale(1, 1);
            transform: translate(58.8522px, 50.979px) scale(1, 1); }
  100% {
    -webkit-transform: translate(58.8522px, 50.979px) scale(1, 1);
            transform: translate(58.8522px, 50.979px) scale(1, 1); } }
  .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- .Cloud_enpo164tlyaj11_ts__wD0yg {
    -webkit-animation: Cloud_enpo164tlyaj11_ts__ts__bFBK7 1640ms linear infinite alternate forwards;
            animation: Cloud_enpo164tlyaj11_ts__ts__bFBK7 1640ms linear infinite alternate forwards; }

@-webkit-keyframes Cloud_enpo164tlyaj11_ts__ts__bFBK7 {
  0% {
    -webkit-transform: translate(70.2683px, 8.46369px) scale(0, 0);
            transform: translate(70.2683px, 8.46369px) scale(0, 0); }
  42.682927% {
    -webkit-transform: translate(70.2683px, 8.46369px) scale(0, 0);
            transform: translate(70.2683px, 8.46369px) scale(0, 0); }
  54.878049% {
    -webkit-transform: translate(70.2683px, 8.46369px) scale(1, 1);
            transform: translate(70.2683px, 8.46369px) scale(1, 1); }
  100% {
    -webkit-transform: translate(70.2683px, 8.46369px) scale(1, 1);
            transform: translate(70.2683px, 8.46369px) scale(1, 1); } }

@keyframes Cloud_enpo164tlyaj11_ts__ts__bFBK7 {
  0% {
    -webkit-transform: translate(70.2683px, 8.46369px) scale(0, 0);
            transform: translate(70.2683px, 8.46369px) scale(0, 0); }
  42.682927% {
    -webkit-transform: translate(70.2683px, 8.46369px) scale(0, 0);
            transform: translate(70.2683px, 8.46369px) scale(0, 0); }
  54.878049% {
    -webkit-transform: translate(70.2683px, 8.46369px) scale(1, 1);
            transform: translate(70.2683px, 8.46369px) scale(1, 1); }
  100% {
    -webkit-transform: translate(70.2683px, 8.46369px) scale(1, 1);
            transform: translate(70.2683px, 8.46369px) scale(1, 1); } }
  .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- .Cloud_enpo164tlyaj12_ts__5mFG5 {
    -webkit-animation: Cloud_enpo164tlyaj12_ts__ts__1c00q 1640ms linear infinite alternate forwards;
            animation: Cloud_enpo164tlyaj12_ts__ts__1c00q 1640ms linear infinite alternate forwards; }

@-webkit-keyframes Cloud_enpo164tlyaj12_ts__ts__1c00q {
  0% {
    -webkit-transform: translate(34.96859px, 25.18003px) rotate(167.99999deg) scale(0, 0);
            transform: translate(34.96859px, 25.18003px) rotate(167.99999deg) scale(0, 0); }
  6.097561% {
    -webkit-transform: translate(34.96859px, 25.18003px) rotate(167.99999deg) scale(0, 0);
            transform: translate(34.96859px, 25.18003px) rotate(167.99999deg) scale(0, 0); }
  18.292683% {
    -webkit-transform: translate(34.96859px, 25.18003px) rotate(167.99999deg) scale(1, 1);
            transform: translate(34.96859px, 25.18003px) rotate(167.99999deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(34.96859px, 25.18003px) rotate(167.99999deg) scale(1, 1);
            transform: translate(34.96859px, 25.18003px) rotate(167.99999deg) scale(1, 1); } }

@keyframes Cloud_enpo164tlyaj12_ts__ts__1c00q {
  0% {
    -webkit-transform: translate(34.96859px, 25.18003px) rotate(167.99999deg) scale(0, 0);
            transform: translate(34.96859px, 25.18003px) rotate(167.99999deg) scale(0, 0); }
  6.097561% {
    -webkit-transform: translate(34.96859px, 25.18003px) rotate(167.99999deg) scale(0, 0);
            transform: translate(34.96859px, 25.18003px) rotate(167.99999deg) scale(0, 0); }
  18.292683% {
    -webkit-transform: translate(34.96859px, 25.18003px) rotate(167.99999deg) scale(1, 1);
            transform: translate(34.96859px, 25.18003px) rotate(167.99999deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(34.96859px, 25.18003px) rotate(167.99999deg) scale(1, 1);
            transform: translate(34.96859px, 25.18003px) rotate(167.99999deg) scale(1, 1); } }
  .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- .Cloud_enpo164tlyaj13_ts__1siIq {
    -webkit-animation: Cloud_enpo164tlyaj13_ts__ts__3XXvO 1640ms linear infinite alternate forwards;
            animation: Cloud_enpo164tlyaj13_ts__ts__3XXvO 1640ms linear infinite alternate forwards; }

@-webkit-keyframes Cloud_enpo164tlyaj13_ts__ts__3XXvO {
  0% {
    -webkit-transform: translate(29.57776px, 26.32595px) rotate(167.99999deg) scale(0, 0);
            transform: translate(29.57776px, 26.32595px) rotate(167.99999deg) scale(0, 0); }
  18.292683% {
    -webkit-transform: translate(29.57776px, 26.32595px) rotate(167.99999deg) scale(0, 0);
            transform: translate(29.57776px, 26.32595px) rotate(167.99999deg) scale(0, 0); }
  30.487805% {
    -webkit-transform: translate(29.57776px, 26.32595px) rotate(167.99999deg) scale(1, 1);
            transform: translate(29.57776px, 26.32595px) rotate(167.99999deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(29.57776px, 26.32595px) rotate(167.99999deg) scale(1, 1);
            transform: translate(29.57776px, 26.32595px) rotate(167.99999deg) scale(1, 1); } }

@keyframes Cloud_enpo164tlyaj13_ts__ts__3XXvO {
  0% {
    -webkit-transform: translate(29.57776px, 26.32595px) rotate(167.99999deg) scale(0, 0);
            transform: translate(29.57776px, 26.32595px) rotate(167.99999deg) scale(0, 0); }
  18.292683% {
    -webkit-transform: translate(29.57776px, 26.32595px) rotate(167.99999deg) scale(0, 0);
            transform: translate(29.57776px, 26.32595px) rotate(167.99999deg) scale(0, 0); }
  30.487805% {
    -webkit-transform: translate(29.57776px, 26.32595px) rotate(167.99999deg) scale(1, 1);
            transform: translate(29.57776px, 26.32595px) rotate(167.99999deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(29.57776px, 26.32595px) rotate(167.99999deg) scale(1, 1);
            transform: translate(29.57776px, 26.32595px) rotate(167.99999deg) scale(1, 1); } }
  .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- .Cloud_enpo164tlyaj14_ts__3ykDI {
    -webkit-animation: Cloud_enpo164tlyaj14_ts__ts__3-CXK 1640ms linear infinite alternate forwards;
            animation: Cloud_enpo164tlyaj14_ts__ts__3-CXK 1640ms linear infinite alternate forwards; }

@-webkit-keyframes Cloud_enpo164tlyaj14_ts__ts__3-CXK {
  0% {
    -webkit-transform: translate(18.77092px, 28.62289px) rotate(167.99999deg) scale(0, 0);
            transform: translate(18.77092px, 28.62289px) rotate(167.99999deg) scale(0, 0); }
  42.682927% {
    -webkit-transform: translate(18.77092px, 28.62289px) rotate(167.99999deg) scale(0, 0);
            transform: translate(18.77092px, 28.62289px) rotate(167.99999deg) scale(0, 0); }
  54.878049% {
    -webkit-transform: translate(18.77092px, 28.62289px) rotate(167.99999deg) scale(1, 1);
            transform: translate(18.77092px, 28.62289px) rotate(167.99999deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(18.77092px, 28.62289px) rotate(167.99999deg) scale(1, 1);
            transform: translate(18.77092px, 28.62289px) rotate(167.99999deg) scale(1, 1); } }

@keyframes Cloud_enpo164tlyaj14_ts__ts__3-CXK {
  0% {
    -webkit-transform: translate(18.77092px, 28.62289px) rotate(167.99999deg) scale(0, 0);
            transform: translate(18.77092px, 28.62289px) rotate(167.99999deg) scale(0, 0); }
  42.682927% {
    -webkit-transform: translate(18.77092px, 28.62289px) rotate(167.99999deg) scale(0, 0);
            transform: translate(18.77092px, 28.62289px) rotate(167.99999deg) scale(0, 0); }
  54.878049% {
    -webkit-transform: translate(18.77092px, 28.62289px) rotate(167.99999deg) scale(1, 1);
            transform: translate(18.77092px, 28.62289px) rotate(167.99999deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(18.77092px, 28.62289px) rotate(167.99999deg) scale(1, 1);
            transform: translate(18.77092px, 28.62289px) rotate(167.99999deg) scale(1, 1); } }
  .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- .Cloud_enpo164tlyaj15_ts__1q-_2 {
    -webkit-animation: Cloud_enpo164tlyaj15_ts__ts__1Doxl 1640ms linear infinite alternate forwards;
            animation: Cloud_enpo164tlyaj15_ts__ts__1Doxl 1640ms linear infinite alternate forwards; }

@-webkit-keyframes Cloud_enpo164tlyaj15_ts__ts__1Doxl {
  0% {
    -webkit-transform: translate(24.18692px, 27.47168px) rotate(167.99999deg) scale(0, 0);
            transform: translate(24.18692px, 27.47168px) rotate(167.99999deg) scale(0, 0); }
  30.487805% {
    -webkit-transform: translate(24.18692px, 27.47168px) rotate(167.99999deg) scale(0, 0);
            transform: translate(24.18692px, 27.47168px) rotate(167.99999deg) scale(0, 0); }
  42.682927% {
    -webkit-transform: translate(24.18692px, 27.47168px) rotate(167.99999deg) scale(1, 1);
            transform: translate(24.18692px, 27.47168px) rotate(167.99999deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(24.18692px, 27.47168px) rotate(167.99999deg) scale(1, 1);
            transform: translate(24.18692px, 27.47168px) rotate(167.99999deg) scale(1, 1); } }

@keyframes Cloud_enpo164tlyaj15_ts__ts__1Doxl {
  0% {
    -webkit-transform: translate(24.18692px, 27.47168px) rotate(167.99999deg) scale(0, 0);
            transform: translate(24.18692px, 27.47168px) rotate(167.99999deg) scale(0, 0); }
  30.487805% {
    -webkit-transform: translate(24.18692px, 27.47168px) rotate(167.99999deg) scale(0, 0);
            transform: translate(24.18692px, 27.47168px) rotate(167.99999deg) scale(0, 0); }
  42.682927% {
    -webkit-transform: translate(24.18692px, 27.47168px) rotate(167.99999deg) scale(1, 1);
            transform: translate(24.18692px, 27.47168px) rotate(167.99999deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(24.18692px, 27.47168px) rotate(167.99999deg) scale(1, 1);
            transform: translate(24.18692px, 27.47168px) rotate(167.99999deg) scale(1, 1); } }
  .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- .Cloud_enpo164tlyaj16_ts__ktWIS {
    -webkit-animation: Cloud_enpo164tlyaj16_ts__ts__3ASRc 1640ms linear infinite alternate forwards;
            animation: Cloud_enpo164tlyaj16_ts__ts__3ASRc 1640ms linear infinite alternate forwards; }

@-webkit-keyframes Cloud_enpo164tlyaj16_ts__ts__3ASRc {
  0% {
    -webkit-transform: translate(39.14001px, 31.73297px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(39.14001px, 31.73297px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  6.097561% {
    -webkit-transform: translate(39.14001px, 31.73297px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(39.14001px, 31.73297px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  18.292683% {
    -webkit-transform: translate(39.14001px, 31.73297px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(39.14001px, 31.73297px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(39.14001px, 31.73297px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(39.14001px, 31.73297px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); } }

@keyframes Cloud_enpo164tlyaj16_ts__ts__3ASRc {
  0% {
    -webkit-transform: translate(39.14001px, 31.73297px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(39.14001px, 31.73297px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  6.097561% {
    -webkit-transform: translate(39.14001px, 31.73297px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(39.14001px, 31.73297px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  18.292683% {
    -webkit-transform: translate(39.14001px, 31.73297px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(39.14001px, 31.73297px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(39.14001px, 31.73297px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(39.14001px, 31.73297px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); } }
  .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- .Cloud_enpo164tlyaj17_ts__3Tw-6 {
    -webkit-animation: Cloud_enpo164tlyaj17_ts__ts__3adhI 1640ms linear infinite alternate forwards;
            animation: Cloud_enpo164tlyaj17_ts__ts__3adhI 1640ms linear infinite alternate forwards; }

@-webkit-keyframes Cloud_enpo164tlyaj17_ts__ts__3adhI {
  0% {
    -webkit-transform: translate(36.38441px, 36.50581px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(36.38441px, 36.50581px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  18.292683% {
    -webkit-transform: translate(36.38441px, 36.50581px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(36.38441px, 36.50581px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  30.487805% {
    -webkit-transform: translate(36.38441px, 36.50581px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(36.38441px, 36.50581px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(36.38441px, 36.50581px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(36.38441px, 36.50581px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); } }

@keyframes Cloud_enpo164tlyaj17_ts__ts__3adhI {
  0% {
    -webkit-transform: translate(36.38441px, 36.50581px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(36.38441px, 36.50581px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  18.292683% {
    -webkit-transform: translate(36.38441px, 36.50581px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(36.38441px, 36.50581px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  30.487805% {
    -webkit-transform: translate(36.38441px, 36.50581px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(36.38441px, 36.50581px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(36.38441px, 36.50581px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(36.38441px, 36.50581px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); } }
  .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- .Cloud_enpo164tlyaj18_ts__19Z12 {
    -webkit-animation: Cloud_enpo164tlyaj18_ts__ts__1BOqd 1640ms linear infinite alternate forwards;
            animation: Cloud_enpo164tlyaj18_ts__ts__1BOqd 1640ms linear infinite alternate forwards; }

@-webkit-keyframes Cloud_enpo164tlyaj18_ts__ts__1BOqd {
  0% {
    -webkit-transform: translate(33.62876px, 41.27864px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(33.62876px, 41.27864px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  30.487805% {
    -webkit-transform: translate(33.62876px, 41.27864px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(33.62876px, 41.27864px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  42.682927% {
    -webkit-transform: translate(33.62876px, 41.27864px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(33.62876px, 41.27864px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(33.62876px, 41.27864px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(33.62876px, 41.27864px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); } }

@keyframes Cloud_enpo164tlyaj18_ts__ts__1BOqd {
  0% {
    -webkit-transform: translate(33.62876px, 41.27864px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(33.62876px, 41.27864px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  30.487805% {
    -webkit-transform: translate(33.62876px, 41.27864px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(33.62876px, 41.27864px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  42.682927% {
    -webkit-transform: translate(33.62876px, 41.27864px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(33.62876px, 41.27864px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(33.62876px, 41.27864px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(33.62876px, 41.27864px) rotate(120.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); } }
  .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- .Cloud_enpo164tlyaj19_ts__2TPon {
    -webkit-animation: Cloud_enpo164tlyaj19_ts__ts__1eYdw 1640ms linear infinite alternate forwards;
            animation: Cloud_enpo164tlyaj19_ts__ts__1eYdw 1640ms linear infinite alternate forwards; }

@-webkit-keyframes Cloud_enpo164tlyaj19_ts__ts__1eYdw {
  0% {
    -webkit-transform: translate(53.34246px, 41.39621px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(53.34246px, 41.39621px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  30.487805% {
    -webkit-transform: translate(53.34246px, 41.39621px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(53.34246px, 41.39621px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  42.682927% {
    -webkit-transform: translate(53.34246px, 41.39621px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(53.34246px, 41.39621px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(53.34246px, 41.39621px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(53.34246px, 41.39621px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1); } }

@keyframes Cloud_enpo164tlyaj19_ts__ts__1eYdw {
  0% {
    -webkit-transform: translate(53.34246px, 41.39621px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(53.34246px, 41.39621px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  30.487805% {
    -webkit-transform: translate(53.34246px, 41.39621px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(53.34246px, 41.39621px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  42.682927% {
    -webkit-transform: translate(53.34246px, 41.39621px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(53.34246px, 41.39621px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(53.34246px, 41.39621px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(53.34246px, 41.39621px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1); } }
  .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- .Cloud_enpo164tlyaj20_ts__2o328 {
    -webkit-animation: Cloud_enpo164tlyaj20_ts__ts__3rqMX 1640ms linear infinite alternate forwards;
            animation: Cloud_enpo164tlyaj20_ts__ts__3rqMX 1640ms linear infinite alternate forwards; }

@-webkit-keyframes Cloud_enpo164tlyaj20_ts__ts__3rqMX {
  0% {
    -webkit-transform: translate(50.58681px, 36.62288px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(50.58681px, 36.62288px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  18.292683% {
    -webkit-transform: translate(50.58681px, 36.62288px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(50.58681px, 36.62288px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  30.487805% {
    -webkit-transform: translate(50.58681px, 36.62288px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(50.58681px, 36.62288px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(50.58681px, 36.62288px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(50.58681px, 36.62288px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1); } }

@keyframes Cloud_enpo164tlyaj20_ts__ts__3rqMX {
  0% {
    -webkit-transform: translate(50.58681px, 36.62288px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(50.58681px, 36.62288px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  18.292683% {
    -webkit-transform: translate(50.58681px, 36.62288px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(50.58681px, 36.62288px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  30.487805% {
    -webkit-transform: translate(50.58681px, 36.62288px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(50.58681px, 36.62288px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(50.58681px, 36.62288px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(50.58681px, 36.62288px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1); } }
  .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- .Cloud_enpo164tlyaj21_ts__2uwCC {
    -webkit-animation: Cloud_enpo164tlyaj21_ts__ts__2IMZj 1640ms linear infinite alternate forwards;
            animation: Cloud_enpo164tlyaj21_ts__ts__2IMZj 1640ms linear infinite alternate forwards; }

@-webkit-keyframes Cloud_enpo164tlyaj21_ts__ts__2IMZj {
  0% {
    -webkit-transform: translate(47.8313px, 31.84999px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(47.8313px, 31.84999px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  6.097561% {
    -webkit-transform: translate(47.8313px, 31.84999px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(47.8313px, 31.84999px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  18.292683% {
    -webkit-transform: translate(47.8313px, 31.84999px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(47.8313px, 31.84999px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(47.8313px, 31.84999px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(47.8313px, 31.84999px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1); } }

@keyframes Cloud_enpo164tlyaj21_ts__ts__2IMZj {
  0% {
    -webkit-transform: translate(47.8313px, 31.84999px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(47.8313px, 31.84999px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  6.097561% {
    -webkit-transform: translate(47.8313px, 31.84999px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(47.8313px, 31.84999px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  18.292683% {
    -webkit-transform: translate(47.8313px, 31.84999px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(47.8313px, 31.84999px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(47.8313px, 31.84999px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(47.8313px, 31.84999px) rotate(239.99999deg) skewX(0deg) skewY(0deg) scale(1, 1); } }
  .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- .Cloud_enpo164tlyaj22_ts__aIK5X {
    -webkit-animation: Cloud_enpo164tlyaj22_ts__ts__uVsXm 1640ms linear infinite alternate forwards;
            animation: Cloud_enpo164tlyaj22_ts__ts__uVsXm 1640ms linear infinite alternate forwards; }

@-webkit-keyframes Cloud_enpo164tlyaj22_ts__ts__uVsXm {
  0% {
    -webkit-transform: translate(50.94846px, 18.94596px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(50.94846px, 18.94596px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  6.097561% {
    -webkit-transform: translate(50.94846px, 18.94596px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(50.94846px, 18.94596px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  18.292683% {
    -webkit-transform: translate(50.94846px, 18.94596px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(50.94846px, 18.94596px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(50.94846px, 18.94596px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(50.94846px, 18.94596px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); } }

@keyframes Cloud_enpo164tlyaj22_ts__ts__uVsXm {
  0% {
    -webkit-transform: translate(50.94846px, 18.94596px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(50.94846px, 18.94596px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  6.097561% {
    -webkit-transform: translate(50.94846px, 18.94596px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(50.94846px, 18.94596px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  18.292683% {
    -webkit-transform: translate(50.94846px, 18.94596px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(50.94846px, 18.94596px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(50.94846px, 18.94596px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(50.94846px, 18.94596px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); } }
  .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- .Cloud_enpo164tlyaj23_ts__4DahI {
    -webkit-animation: Cloud_enpo164tlyaj23_ts__ts__1b6Ig 1640ms linear infinite alternate forwards;
            animation: Cloud_enpo164tlyaj23_ts__ts__1b6Ig 1640ms linear infinite alternate forwards; }

@-webkit-keyframes Cloud_enpo164tlyaj23_ts__ts__1b6Ig {
  0% {
    -webkit-transform: translate(55.72127px, 16.19044px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(55.72127px, 16.19044px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  18.292683% {
    -webkit-transform: translate(55.72127px, 16.19044px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(55.72127px, 16.19044px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  30.487805% {
    -webkit-transform: translate(55.72127px, 16.19044px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(55.72127px, 16.19044px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(55.72127px, 16.19044px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(55.72127px, 16.19044px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); } }

@keyframes Cloud_enpo164tlyaj23_ts__ts__1b6Ig {
  0% {
    -webkit-transform: translate(55.72127px, 16.19044px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(55.72127px, 16.19044px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  18.292683% {
    -webkit-transform: translate(55.72127px, 16.19044px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(55.72127px, 16.19044px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  30.487805% {
    -webkit-transform: translate(55.72127px, 16.19044px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(55.72127px, 16.19044px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(55.72127px, 16.19044px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(55.72127px, 16.19044px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); } }
  .Cloud_cloudSpinner__2ocp- .Cloud_cloudSpinnerSvg__2tfy- .Cloud_enpo164tlyaj24_ts__JgoFC {
    -webkit-animation: Cloud_enpo164tlyaj24_ts__ts__3mykW 1640ms linear infinite alternate forwards;
            animation: Cloud_enpo164tlyaj24_ts__ts__3mykW 1640ms linear infinite alternate forwards; }

@-webkit-keyframes Cloud_enpo164tlyaj24_ts__ts__3mykW {
  0% {
    -webkit-transform: translate(60.49418px, 13.43477px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(60.49418px, 13.43477px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  30.487805% {
    -webkit-transform: translate(60.49418px, 13.43477px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(60.49418px, 13.43477px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  42.682927% {
    -webkit-transform: translate(60.49418px, 13.43477px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(60.49418px, 13.43477px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(60.49418px, 13.43477px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(60.49418px, 13.43477px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); } }

@keyframes Cloud_enpo164tlyaj24_ts__ts__3mykW {
  0% {
    -webkit-transform: translate(60.49418px, 13.43477px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(60.49418px, 13.43477px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  30.487805% {
    -webkit-transform: translate(60.49418px, 13.43477px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0);
            transform: translate(60.49418px, 13.43477px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(0, 0); }
  42.682927% {
    -webkit-transform: translate(60.49418px, 13.43477px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(60.49418px, 13.43477px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); }
  100% {
    -webkit-transform: translate(60.49418px, 13.43477px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1);
            transform: translate(60.49418px, 13.43477px) rotate(-30.00001deg) skewX(0deg) skewY(0deg) scale(1, 1); } }

.Button_button__Nfhxt {
  outline: none; }
  .theme-dark .Button_button__Nfhxt.bp3-button {
    background: #1a1e22; }
  .theme-dark .Button_button__Nfhxt.bp3-intent-primary {
    background: #151a1d; }
  .theme-dark .Button_button__Nfhxt.bp3-intent-danger {
    background: #e74c3c; }
  .theme-dark .Button_button__Nfhxt.bp3-intent-success {
    background: #6ab04c; }
  .theme-dark .Button_button__Nfhxt.bp3-intent-success:hover {
    background: #579a3a; }
  .theme-dark .Button_button__Nfhxt:hover:not(:disabled):not(.bp3-intent-success):not(.bp3-intent-danger) {
    color: #6ab04c; }
    .theme-dark .Button_button__Nfhxt:hover:not(:disabled):not(.bp3-intent-success):not(.bp3-intent-danger) svg {
      fill: #6ab04c; }

.theme-dark .Button_active__3BxT0 {
  background: none;
  color: #6ab04c !important; }
  .theme-dark .Button_active__3BxT0 svg {
    fill: #6ab04c; }

.theme-dark .App_app__2ziFi a {
  color: #6ab04c; }

.QueryConsole_wrapper__g_6Y9 {
  display: flex;
  flex-direction: column;
  height: 100%; }

.TagContainer_tagContainer__1wSnp {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  z-index: 10;
  padding: 5px; }
  .TagContainer_tagContainer__1wSnp > * {
    margin-left: 10px; }

.ace_tooltip.ace_doc-tooltip {
  background: #3d3f41;
  display: flex !important;
  flex-direction: column;
  padding: 8px;
  border: none; }

.theme-dark .Editor_editorContainer__3LsiI {
  flex: 1 1;
  position: relative;
  height: 100%; }
  .theme-dark .Editor_editorContainer__3LsiI .Editor_gremlifyEditor__2FGYd.ace_editor {
    font-size: 1em;
    background: #1a1e22; }
    .theme-dark .Editor_editorContainer__3LsiI .Editor_gremlifyEditor__2FGYd.ace_editor * {
      font: 14px/normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace !important; }
  .theme-dark .Editor_editorContainer__3LsiI .ace_string {
    color: #6ab04c; }
  .theme-dark .Editor_editorContainer__3LsiI .ace_identifier, .theme-dark .Editor_editorContainer__3LsiI .ace_function {
    color: #ed4c67; }
  .theme-dark .Editor_editorContainer__3LsiI .ace_numeric {
    color: #e74c3c; }
  .theme-dark .Editor_editorContainer__3LsiI .ace_search {
    background: #1a1e22;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    color: #d3d3d3; }
    .theme-dark .Editor_editorContainer__3LsiI .ace_search input {
      background: #151a1d;
      padding: 5px;
      border: none;
      color: #d3d3d3;
      outline: none; }
    .theme-dark .Editor_editorContainer__3LsiI .ace_search .ace_nomatch {
      outline: none; }
    .theme-dark .Editor_editorContainer__3LsiI .ace_search .ace_searchbtn, .theme-dark .Editor_editorContainer__3LsiI .ace_search .ace_button {
      background: #1a1e22;
      color: #d3d3d3;
      padding: 5px;
      border: none; }
  .theme-dark .Editor_editorContainer__3LsiI .ace_gutter {
    background: none; }
  .theme-dark .Editor_editorContainer__3LsiI .Editor_upload__3cn3z {
    min-width: 0;
    min-height: 0;
    padding: 2px 6px; }

.theme-dark .ace_content {
  background: #1a1e22; }

.theme-dark .ace_completion-highlight {
  color: #6ab04c !important; }

.ace_autocomplete .ace_line {
  color: white !important; }

.theme-dark .ace_autocomplete .ace_active-line {
  background: rgba(138, 155, 168, 0.05) !important; }

.ace_editor.ace-twilight {
  background: #141511; }

@-webkit-keyframes QueryToolbar_fade-in__2jgwJ {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@keyframes QueryToolbar_fade-in__2jgwJ {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@-webkit-keyframes QueryToolbar_pop__3-gaa {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes QueryToolbar_pop__3-gaa {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes QueryToolbar_float__3VrGO {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes QueryToolbar_float__3VrGO {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes QueryToolbar_slide-in-fade__Cu9nR {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes QueryToolbar_slide-in-fade__Cu9nR {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.QueryToolbar_queryToolbar__3gMtY {
  display: flex;
  flex-direction: column;
  min-height: 41px; }
  .QueryToolbar_queryToolbar__3gMtY .QueryToolbar_actionsWrapper__roSnN {
    display: flex;
    justify-content: flex-end;
    flex: 1 1; }
    .QueryToolbar_queryToolbar__3gMtY .QueryToolbar_actionsWrapper__roSnN .QueryToolbar_actions__3rf6j {
      justify-content: flex-end;
      display: flex;
      flex: 3 1; }
  .QueryToolbar_queryToolbar__3gMtY .QueryToolbar_tabs__2r9Zx {
    display: flex;
    flex: 7 1; }
  .QueryToolbar_queryToolbar__3gMtY .QueryToolbar_format__V-HNg {
    -webkit-animation: QueryToolbar_fade-in__2jgwJ 0.3s ease-in-out;
            animation: QueryToolbar_fade-in__2jgwJ 0.3s ease-in-out;
    font-size: 12px;
    padding: 8px 6px; }

.QueryTabs_queryTabs__wj6zC {
  display: flex;
  overflow-x: auto; }
  .theme-dark .QueryTabs_queryTabs__wj6zC .QueryTabs_tabList__lzwf1 {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex; }
    .theme-dark .QueryTabs_queryTabs__wj6zC .QueryTabs_tabList__lzwf1 li:last-child .QueryTabs_tab__3hJjs {
      border-top-right-radius: 5px; }
    .theme-dark .QueryTabs_queryTabs__wj6zC .QueryTabs_tabList__lzwf1 .QueryTabs_tab__3hJjs {
      padding: 8px 6px;
      text-align: left;
      cursor: pointer;
      border-top: 1px solid #060809;
      border-right: 1px solid #060809;
      border-bottom: 2px solid transparent; }
      .theme-dark .QueryTabs_queryTabs__wj6zC .QueryTabs_tabList__lzwf1 .QueryTabs_tab__3hJjs.QueryTabs_selected__CK6zG {
        border-bottom: 2px solid #6ab04c; }
        .theme-dark .QueryTabs_queryTabs__wj6zC .QueryTabs_tabList__lzwf1 .QueryTabs_tab__3hJjs.QueryTabs_selected__CK6zG .QueryTabs_tabText__31kmR {
          cursor: auto; }
      .theme-dark .QueryTabs_queryTabs__wj6zC .QueryTabs_tabList__lzwf1 .QueryTabs_tab__3hJjs .QueryTabs_inner__3FR4F {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .theme-dark .QueryTabs_queryTabs__wj6zC .QueryTabs_tabList__lzwf1 .QueryTabs_tab__3hJjs .QueryTabs_inner__3FR4F:hover .QueryTabs_remove__KnrjI.QueryTabs_removable__1RgSw {
          visibility: visible; }
        .theme-dark .QueryTabs_queryTabs__wj6zC .QueryTabs_tabList__lzwf1 .QueryTabs_tab__3hJjs .QueryTabs_inner__3FR4F .QueryTabs_remove__KnrjI {
          visibility: hidden; }
        .theme-dark .QueryTabs_queryTabs__wj6zC .QueryTabs_tabList__lzwf1 .QueryTabs_tab__3hJjs .QueryTabs_inner__3FR4F .QueryTabs_tabText__31kmR {
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
          max-width: inherit;
          cursor: pointer; }

.Results_results__Uquyr {
  height: calc(100% - 50px);
  position: absolute;
  width: 100%; }
  .theme-dark .Results_results__Uquyr {
    display: flex;
    overflow: auto;
    /* background of the scrollbar except button or resizer */
    /* scrollbar itself */
    /* set button(top and bottom of the scrollbar) */
    white-space: nowrap; }
    .theme-dark .Results_results__Uquyr > .react-json-view {
      background: #1a1e22 !important; }
      .theme-dark .Results_results__Uquyr > .react-json-view .copy-icon svg,
      .theme-dark .Results_results__Uquyr > .react-json-view .collapsed-icon svg,
      .theme-dark .Results_results__Uquyr > .react-json-view .expanded-icon svg {
        color: #6ab04c !important; }
      .theme-dark .Results_results__Uquyr > .react-json-view .variable-value > div {
        color: #6ab04c !important; }
      .theme-dark .Results_results__Uquyr > .react-json-view .string-value,
      .theme-dark .Results_results__Uquyr > .react-json-view .variable-value,
      .theme-dark .Results_results__Uquyr > .react-json-view .node-ellipsis {
        color: #6ab04c !important; }
    .theme-dark .Results_results__Uquyr.Results_hasError__2PCtA > .react-json-view {
      background: #1a1e22 !important; }
      .theme-dark .Results_results__Uquyr.Results_hasError__2PCtA > .react-json-view .copy-icon svg,
      .theme-dark .Results_results__Uquyr.Results_hasError__2PCtA > .react-json-view .collapsed-icon svg,
      .theme-dark .Results_results__Uquyr.Results_hasError__2PCtA > .react-json-view .expanded-icon svg {
        color: #e74c3c !important; }
      .theme-dark .Results_results__Uquyr.Results_hasError__2PCtA > .react-json-view .variable-value > div {
        color: #e74c3c !important; }
      .theme-dark .Results_results__Uquyr.Results_hasError__2PCtA > .react-json-view .string-value,
      .theme-dark .Results_results__Uquyr.Results_hasError__2PCtA > .react-json-view .variable-value,
      .theme-dark .Results_results__Uquyr.Results_hasError__2PCtA > .react-json-view .node-ellipsis {
        color: #e74c3c !important; }
    .theme-dark .Results_results__Uquyr::-webkit-scrollbar {
      background-color: transparent;
      width: 8px;
      height: 8px; }
    .theme-dark .Results_results__Uquyr::-webkit-scrollbar-track {
      background: transparent; }
    .theme-dark .Results_results__Uquyr::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 16px; }
    .theme-dark .Results_results__Uquyr::-webkit-scrollbar-button {
      display: none; }
    .theme-dark .Results_results__Uquyr::-webkit-scrollbar-corner {
      background: transparent; }
    .theme-dark .Results_results__Uquyr .bp3-non-ideal-state {
      padding-top: 20px;
      justify-content: flex-start; }
    .theme-dark .Results_results__Uquyr .Results_error__34bon {
      color: #e74c3c; }
    .theme-dark .Results_results__Uquyr .Results_toolbar__1EQDU {
      padding: 5px;
      z-index: 9;
      position: -webkit-sticky;
      position: sticky;
      top: 0; }
    .theme-dark .Results_results__Uquyr .Results_json__10moF {
      position: absolute; }

.Toolbar_toolbar__3KMt4 {
  text-align: left;
  height: 40px;
  display: flex;
  justify-content: flex-end; }
  .theme-dark .Toolbar_toolbar__3KMt4 {
    border-bottom: 1px solid #060809; }
    .theme-dark .Toolbar_toolbar__3KMt4 .bp3-popover-target {
      height: 100%; }
    .theme-dark .Toolbar_toolbar__3KMt4 .Toolbar_button__1NfTJ {
      height: 100%;
      border-radius: 0;
      width: 50px; }

.theme-dark div.SandBox_content__1zd9G {
  position: relative;
  flex: 1 1;
  display: flex;
  /** TODO:move all of these to the wrapper **/ }
  .theme-dark div.SandBox_content__1zd9G .reflex-element {
    background: #1a1e22; }
  .theme-dark div.SandBox_content__1zd9G .reflex-container.horizontal > .reflex-splitter {
    border-bottom: 0;
    border-top: 1px solid #060809;
    height: 1px;
    z-index: 10; }
  .theme-dark div.SandBox_content__1zd9G .reflex-container.vertical > .reflex-splitter {
    background-color: #060809;
    border-left: 0;
    border-right: 1px solid #060809;
    width: 1px;
    z-index: 10; }
  .theme-dark div.SandBox_content__1zd9G .SandBox_sideBar__XWF8a {
    flex: 0.125 1;
    min-width: 200px;
    overflow: hidden; }

.theme-dark .react-joyride__tooltip {
  background: #1a1e22 !important;
  color: #d3d3d3 !important;
  font-family: "Open Sans", serif !important;
  font-weight: 300; }
  .theme-dark .react-joyride__tooltip button {
    outline: none; }
  .theme-dark .react-joyride__tooltip button[data-action="primary"] {
    background: #151a1d !important;
    padding: 10px 24px !important; }
    .theme-dark .react-joyride__tooltip button[data-action="primary"]:hover {
      color: #6ab04c !important; }
  .theme-dark .react-joyride__tooltip button[data-action="back"] {
    color: #d3d3d3 !important; }
    .theme-dark .react-joyride__tooltip button[data-action="back"]:hover {
      color: #6ab04c !important; }
  .theme-dark .react-joyride__tooltip button[data-action="close"] {
    stroke: #d3d3d3 !important; }

.theme-dark .__floater__arrow svg polygon {
  fill: #1a1e22 !important; }


@-webkit-keyframes Sidebar_fade-in__CxWVP {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@keyframes Sidebar_fade-in__CxWVP {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@-webkit-keyframes Sidebar_pop__1Cyy9 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes Sidebar_pop__1Cyy9 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes Sidebar_float__Lywie {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes Sidebar_float__Lywie {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes Sidebar_slide-in-fade__stnVa {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes Sidebar_slide-in-fade__stnVa {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.Sidebar_sideBar__14m87 {
  display: flex; }

.theme-dark .Title_sideBarTitle__1e2St {
  text-align: left;
  border-bottom: 1px solid #060809;
  padding: 14px 10px;
  font-size: 13px;
  font-weight: bold; }
  .theme-dark .Title_sideBarTitle__1e2St:not(.Title_primary__2EjDt) {
    cursor: pointer;
    background: #171b1f; }

.Title_sideBarTitle__1e2St .Title_caret__rPC9c {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin-right: 5px; }
  .Title_sideBarTitle__1e2St .Title_caret__rPC9c.Title_open__2UgvP {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }


.Section_sideBarSection__2C87r {
  padding: 10px; }
  .theme-dark .Section_sideBarSection__2C87r {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

@-webkit-keyframes SideMenu_fade-in__3A75V {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@keyframes SideMenu_fade-in__3A75V {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@-webkit-keyframes SideMenu_pop__KKqsv {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes SideMenu_pop__KKqsv {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes SideMenu_float__1XcmV {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes SideMenu_float__1XcmV {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes SideMenu_slide-in-fade__nBz6u {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes SideMenu_slide-in-fade__nBz6u {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.theme-dark .SideMenu_siteSideMenu__saBEr {
  display: flex;
  background-color: #1a1e22;
  height: 100%; }
  .theme-dark .SideMenu_siteSideMenu__saBEr .SideMenu_content__fdaNI {
    width: 250px;
    display: flex;
    flex-direction: column; }
  .theme-dark .SideMenu_siteSideMenu__saBEr.SideMenu_open__1DCJq {
    border-right: 1px solid #060809; }
  .theme-dark .SideMenu_siteSideMenu__saBEr .SideMenu_mainTitle__3sG64 {
    font-size: 13px; }
  .theme-dark .SideMenu_siteSideMenu__saBEr .scrollbar-container {
    display: none; }
  .theme-dark .SideMenu_siteSideMenu__saBEr:hover .scrollbar-container {
    display: block; }
  .theme-dark .SideMenu_siteSideMenu__saBEr .SideMenu_iconsBar__2tz27 {
    min-width: 30px;
    padding: 0 10px;
    border-right: 1px solid #060809;
    display: flex;
    flex-direction: column; }
    .theme-dark .SideMenu_siteSideMenu__saBEr .SideMenu_iconsBar__2tz27 .SideMenu_iconItem__1VnuR {
      margin: 10px 0;
      width: 32px;
      height: 32px;
      background: none;
      border: none;
      outline: none;
      padding: 0; }
      .theme-dark .SideMenu_siteSideMenu__saBEr .SideMenu_iconsBar__2tz27 .SideMenu_iconItem__1VnuR button,
      .theme-dark .SideMenu_siteSideMenu__saBEr .SideMenu_iconsBar__2tz27 .SideMenu_iconItem__1VnuR .bp3-icon {
        color: #d3d3d3; }
      .theme-dark .SideMenu_siteSideMenu__saBEr .SideMenu_iconsBar__2tz27 .SideMenu_iconItem__1VnuR:not(.bp3-icon) > svg {
        stroke: #d3d3d3;
        fill: #d3d3d3; }
      .theme-dark .SideMenu_siteSideMenu__saBEr .SideMenu_iconsBar__2tz27 .SideMenu_iconItem__1VnuR:hover {
        cursor: pointer; }
      .theme-dark .theme-dark .SideMenu_siteSideMenu__saBEr .SideMenu_iconsBar__2tz27 .SideMenu_iconItem__1VnuR:active button,
      .theme-dark .theme-dark .SideMenu_siteSideMenu__saBEr .SideMenu_iconsBar__2tz27 .SideMenu_iconItem__1VnuR:active .bp3-icon, .theme-dark .theme-dark .SideMenu_siteSideMenu__saBEr .SideMenu_iconsBar__2tz27 .SideMenu_iconItem__1VnuR.SideMenu_active__1gDk9 button,
      .theme-dark .theme-dark .SideMenu_siteSideMenu__saBEr .SideMenu_iconsBar__2tz27 .SideMenu_iconItem__1VnuR.SideMenu_active__1gDk9 .bp3-icon, .theme-dark .theme-dark .SideMenu_siteSideMenu__saBEr .SideMenu_iconsBar__2tz27 .SideMenu_iconItem__1VnuR:hover button,
      .theme-dark .theme-dark .SideMenu_siteSideMenu__saBEr .SideMenu_iconsBar__2tz27 .SideMenu_iconItem__1VnuR:hover .bp3-icon {
        color: #6ab04c; }
      .theme-dark .theme-dark .SideMenu_siteSideMenu__saBEr .SideMenu_iconsBar__2tz27 .SideMenu_iconItem__1VnuR:active:not(.bp3-icon) > svg, .theme-dark .theme-dark .SideMenu_siteSideMenu__saBEr .SideMenu_iconsBar__2tz27 .SideMenu_iconItem__1VnuR.SideMenu_active__1gDk9:not(.bp3-icon) > svg, .theme-dark .theme-dark .SideMenu_siteSideMenu__saBEr .SideMenu_iconsBar__2tz27 .SideMenu_iconItem__1VnuR:hover:not(.bp3-icon) > svg {
        stroke: #6ab04c;
        fill: #6ab04c; }

.theme-dark .SideMenu_updates__2EKbf {
  position: relative; }
  .theme-dark .SideMenu_updates__2EKbf .SideMenu_dotIcon__32Pqa {
    position: absolute;
    right: 3px;
    top: -3px;
    z-index: 10; }
    .theme-dark .SideMenu_updates__2EKbf .SideMenu_dotIcon__32Pqa svg {
      color: #e74c3c;
      -webkit-animation: SideMenu_pop__KKqsv 0.4s ease;
              animation: SideMenu_pop__KKqsv 0.4s ease; }

.FormGroup_gyFormGroup__2_iFO label {
  font-size: 10px;
  text-align: left; }

.theme-dark .FormGroup_gyFormGroup__2_iFO textarea, .theme-dark .FormGroup_gyFormGroup__2_iFO input {
  color: #d3d3d3;
  background: #151a1d; }

.theme-dark .FormGroup_gyFormGroup__2_iFO textarea:focus, .theme-dark .FormGroup_gyFormGroup__2_iFO input:focus {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.9);
  outline: none; }

.InputGroup_inputGroup__3SrQd {
  outline: none; }
  .InputGroup_inputGroup__3SrQd .bp3-input:not([type="search"]) {
    padding: 6px;
    height: unset; }
  .InputGroup_inputGroup__3SrQd * {
    pointer-events: auto !important; }
  .theme-dark .InputGroup_inputGroup__3SrQd textarea, .theme-dark .InputGroup_inputGroup__3SrQd input {
    color: #d3d3d3;
    background: #151a1d; }
  .theme-dark .InputGroup_inputGroup__3SrQd textarea:focus, .theme-dark .InputGroup_inputGroup__3SrQd input:focus {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.9);
    outline: none; }
    .theme-dark .InputGroup_inputGroup__3SrQd textarea:focus:not([type="search"]), .theme-dark .InputGroup_inputGroup__3SrQd input:focus:not([type="search"]) {
      height: auto;
      padding: 6px; }

.TextArea_gyTextarea__1-mDo {
  width: 100%; }

.TextArea_nonResizeable__3jLhI {
  resize: none; }

.theme-dark .Metadata_metadata__7tpq9 .Metadata_formGroup__7fvTn {
  text-align: left; }

.theme-dark .Metadata_metadata__7tpq9 .Metadata_privacyWrapper__2q-aX {
  text-align: left; }

.theme-dark .Switch_switch__1Tg3o .bp3-control-indicator {
  outline: none !important; }

.theme-dark .Switch_switch__1Tg3o input:checked ~ .bp3-control-indicator {
  background: rgba(16, 22, 26, 0.5) !important; }
  .theme-dark .Switch_switch__1Tg3o input:checked ~ .bp3-control-indicator:before {
    background: #579a3a !important;
    box-shadow: none; }


.theme-dark .Themes_themes__2XbAG .Themes_themeList__1fLpg {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  .theme-dark .Themes_themes__2XbAG .Themes_themeList__1fLpg .Themes_themeItem__3dKW2 {
    margin-bottom: 20px;
    display: flex;
    align-items: center; }
    .theme-dark .Themes_themes__2XbAG .Themes_themeList__1fLpg .Themes_themeItem__3dKW2 .Themes_circleSvg__2gn94 {
      width: 18px;
      height: 18px;
      margin-right: 16px; }
      .theme-dark .Themes_themes__2XbAG .Themes_themeList__1fLpg .Themes_themeItem__3dKW2 .Themes_circleSvg__2gn94 circle {
        -webkit-transition: all 0.8s ease;
        transition: all 0.8s ease; }
    .theme-dark .Themes_themes__2XbAG .Themes_themeList__1fLpg .Themes_themeItem__3dKW2 .Themes_themeName__P33Zp {
      margin-right: 10px; }
    .theme-dark .Themes_themes__2XbAG .Themes_themeList__1fLpg .Themes_themeItem__3dKW2:hover {
      cursor: pointer;
      color: #d3d3d3; }
      .theme-dark .Themes_themes__2XbAG .Themes_themeList__1fLpg .Themes_themeItem__3dKW2:hover circle {
        -webkit-transform: scale(2);
                transform: scale(2); }

.History_history__2AFTJ {
  padding: 10px; }
  .theme-dark .History_history__2AFTJ {
    text-align: left;
    z-index: 11; }
    .theme-dark .History_history__2AFTJ .History_historyList__1RHSK {
      max-height: 650px;
      overflow-y: auto;
      padding: 0;
      list-style-type: none; }
      .theme-dark .History_history__2AFTJ .History_historyList__1RHSK li:not(:last-child) {
        margin-bottom: 10px; }
      .theme-dark .History_history__2AFTJ .History_historyList__1RHSK .History_historyItem__28iDm {
        cursor: pointer;
        margin-bottom: 10px; }
        .theme-dark .History_history__2AFTJ .History_historyList__1RHSK .History_historyItem__28iDm .History_query__11u3a {
          flex: 1 1;
          white-space: pre-wrap;
          font-family: Courier, serif;
          margin: 0;
          font-size: 12px; }
          .theme-dark .History_history__2AFTJ .History_historyList__1RHSK .History_historyItem__28iDm .History_query__11u3a:hover {
            color: #6ab04c; }

.PaginatedList_paginatedList__HoR4P {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100%; }
  .theme-dark .PaginatedList_paginatedList__HoR4P ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
  .theme-dark .PaginatedList_paginatedList__HoR4P .PaginatedList_navControls__2Cndp {
    display: flex;
    justify-content: space-between;
    margin: 10px 80px; }
  .theme-dark .PaginatedList_paginatedList__HoR4P .PaginatedList_navButton__3cvQB {
    outline: none; }

@-webkit-keyframes Updates_fade-in__3u-XI {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@keyframes Updates_fade-in__3u-XI {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@-webkit-keyframes Updates_pop__2aR-A {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes Updates_pop__2aR-A {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes Updates_float__2pEZT {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes Updates_float__2pEZT {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes Updates_slide-in-fade__2M9DK {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes Updates_slide-in-fade__2M9DK {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.theme-dark .Updates_update__1j-lY {
  white-space: pre-wrap;
  word-break: break-word;
  text-align: left;
  margin-bottom: 10px;
  background: rgba(138, 155, 168, 0.05);
  font-size: 12px;
  -webkit-animation: Updates_pop__2aR-A 0.4s ease-in-out;
          animation: Updates_pop__2aR-A 0.4s ease-in-out; }

@-webkit-keyframes Templates_fade-in__21a_V {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@keyframes Templates_fade-in__21a_V {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@-webkit-keyframes Templates_pop__3xv37 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes Templates_pop__3xv37 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes Templates_float__3k_rW {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes Templates_float__3k_rW {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes Templates_slide-in-fade__3JnxB {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes Templates_slide-in-fade__3JnxB {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.Templates_templates__2HAjV {
  position: relative;
  flex: 1 1;
  display: flex; }
  .Templates_templates__2HAjV .Templates_templatesWrapper__1PVKN {
    flex: 1 1;
    display: flex;
    flex-direction: column; }
  .Templates_templates__2HAjV .Templates_listWrapper__OXIvF {
    flex: 1 1; }
  .theme-dark .Templates_templates__2HAjV .Templates_flipScene__uob33 {
    height: 500px; }
  .theme-dark .Templates_templates__2HAjV .Templates_confirmation__U5LHM {
    -webkit-animation: Templates_fade-in__21a_V 0.3s ease-in-out;
            animation: Templates_fade-in__21a_V 0.3s ease-in-out;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    background: rgba(26, 30, 34, 0.81);
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 5px; }
    .theme-dark .Templates_templates__2HAjV .Templates_confirmation__U5LHM .Templates_buttonGroup__2esDb {
      width: 100%;
      padding: 0 20px; }
    .theme-dark .Templates_templates__2HAjV .Templates_confirmation__U5LHM .Templates_confirmationContent__3fAAc {
      padding-top: 100px;
      justify-content: flex-start; }
  .Templates_templates__2HAjV .Templates_spinnerWrapper__2zhnY {
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    -webkit-animation: Templates_fade-in__21a_V 0.3s ease-in-out;
            animation: Templates_fade-in__21a_V 0.3s ease-in-out;
    width: 100%;
    height: 100%; }
    .theme-dark .Templates_templates__2HAjV .Templates_spinnerWrapper__2zhnY {
      -webkit-backdrop-filter: blur(2px);
              backdrop-filter: blur(2px);
      background: rgba(26, 30, 34, 0.81); }
  .theme-dark .Templates_templates__2HAjV .Templates_description__1tKX4 {
    text-align: left;
    line-height: 24px; }
  .theme-dark .Templates_templates__2HAjV .Templates_select__GhJPa {
    width: 100%; }
  .theme-dark .Templates_templates__2HAjV .Templates_templateList__1bz8D li:not(:last-child) {
    margin-bottom: 10px; }
  .theme-dark .Templates_templates__2HAjV .Templates_templateCard__3U95k {
    padding: 15px;
    height: 100px; }
    .theme-dark .Templates_templates__2HAjV .Templates_templateCard__3U95k:hover {
      color: #d3d3d3;
      background: #6ab04c; }
      .theme-dark .Templates_templates__2HAjV .Templates_templateCard__3U95k:hover h3 {
        color: #d3d3d3; }
    .theme-dark .Templates_templates__2HAjV .Templates_templateCard__3U95k h3 {
      margin: 0;
      text-align: left;
      color: #6ab04c; }
    .theme-dark .Templates_templates__2HAjV .Templates_templateCard__3U95k p {
      text-align: left;
      padding: 5px 0;
      font-size: 12px; }

@-webkit-keyframes Community_fade-in__2m_Cv {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@keyframes Community_fade-in__2m_Cv {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@-webkit-keyframes Community_pop__3U-Ji {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes Community_pop__3U-Ji {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes Community_float__4xxc_ {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes Community_float__4xxc_ {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes Community_slide-in-fade__3Jl8l {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes Community_slide-in-fade__3Jl8l {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.Community_community__3nB4p p {
  text-align: left;
  font-size: 12px; }

.theme-dark .Community_community__3nB4p .Community_mail__2np_8 {
  color: #6ab04c; }

.Community_community__3nB4p .Community_gremlinFloat__1XxKr {
  width: 100px;
  -webkit-animation: Community_float__4xxc_ 3s ease-in-out infinite;
          animation: Community_float__4xxc_ 3s ease-in-out infinite; }

.Community_community__3nB4p .Community_imgBig__3DbUD {
  width: 150px; }

@-webkit-keyframes Help_fade-in__3XXpe {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@keyframes Help_fade-in__3XXpe {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@-webkit-keyframes Help_pop__3ERj- {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes Help_pop__3ERj- {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes Help_float__2EuVx {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes Help_float__2EuVx {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes Help_slide-in-fade__3IYIA {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes Help_slide-in-fade__3IYIA {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.Help_help__1ZyPH {
  text-align: left; }
  .Help_help__1ZyPH p {
    text-align: left;
    font-size: 12px; }
  .theme-dark .Help_help__1ZyPH li {
    padding: 10px 0;
    line-height: 24px; }
    .theme-dark .Help_help__1ZyPH li .bp3-tag {
      background: rgba(138, 155, 168, 0.05);
      color: #6ab04c; }

.FavouriteQueries_favouriteQueries__1keK- {
  padding: 10px; }
  .FavouriteQueries_favouriteQueries__1keK- .FavouriteQueries_spinnerWrapper__3hd1m {
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    -webkit-animation: FavouriteQueries_fade-in__2mV8i 0.3s ease-in-out;
            animation: FavouriteQueries_fade-in__2mV8i 0.3s ease-in-out;
    width: 100%;
    height: 100%; }
    .theme-dark .FavouriteQueries_favouriteQueries__1keK- .FavouriteQueries_spinnerWrapper__3hd1m {
      -webkit-backdrop-filter: blur(2px);
              backdrop-filter: blur(2px);
      background: rgba(26, 30, 34, 0.81); }
  .theme-dark .FavouriteQueries_favouriteQueries__1keK- {
    text-align: left;
    z-index: 11; }
    .theme-dark .FavouriteQueries_favouriteQueries__1keK- .FavouriteQueries_favouriteQueriesList__2pVK5 {
      max-height: 650px;
      overflow-y: auto;
      padding: 0;
      list-style-type: none; }
      .theme-dark .FavouriteQueries_favouriteQueries__1keK- .FavouriteQueries_favouriteQueriesList__2pVK5 li:not(:last-child) {
        margin-bottom: 10px; }
      .theme-dark .FavouriteQueries_favouriteQueries__1keK- .FavouriteQueries_favouriteQueriesList__2pVK5 .FavouriteQueries_queryItem__3H44t {
        cursor: pointer;
        margin-bottom: 10px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .theme-dark .FavouriteQueries_favouriteQueries__1keK- .FavouriteQueries_favouriteQueriesList__2pVK5 .FavouriteQueries_queryItem__3H44t .FavouriteQueries_actions__3LZSK {
          display: none;
          position: absolute;
          right: 0;
          padding: 5px; }
          .theme-dark .FavouriteQueries_favouriteQueries__1keK- .FavouriteQueries_favouriteQueriesList__2pVK5 .FavouriteQueries_queryItem__3H44t .FavouriteQueries_actions__3LZSK .FavouriteQueries_actionButton__3H0xS {
            min-width: unset;
            min-height: unset; }
        .theme-dark .FavouriteQueries_favouriteQueries__1keK- .FavouriteQueries_favouriteQueriesList__2pVK5 .FavouriteQueries_queryItem__3H44t:hover .FavouriteQueries_actions__3LZSK {
          display: block; }
        .theme-dark .FavouriteQueries_favouriteQueries__1keK- .FavouriteQueries_favouriteQueriesList__2pVK5 .FavouriteQueries_queryItem__3H44t .FavouriteQueries_query__3vuY7 {
          flex: 1 1;
          white-space: pre-wrap;
          font-family: Courier, serif;
          margin: 0;
          font-size: 12px; }
          .theme-dark .FavouriteQueries_favouriteQueries__1keK- .FavouriteQueries_favouriteQueriesList__2pVK5 .FavouriteQueries_queryItem__3H44t .FavouriteQueries_query__3vuY7:hover {
            color: #6ab04c; }

.theme-dark .SideBar_sideBar__3q1fM {
  position: absolute;
  right: 0;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 30px; }
  .theme-dark .SideBar_sideBar__3q1fM .SideBar_tabs__3BwmF {
    margin-top: 300px;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
    .theme-dark .SideBar_sideBar__3q1fM .SideBar_tabs__3BwmF .SideBar_tab__2zO1A {
      text-transform: uppercase;
      padding: 8px 16px;
      margin: 0 5px; }

@-webkit-keyframes GraphBuildr_fade-in__2G2jP {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@keyframes GraphBuildr_fade-in__2G2jP {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@-webkit-keyframes GraphBuildr_pop__-L62Q {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes GraphBuildr_pop__-L62Q {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes GraphBuildr_float__g9R9h {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes GraphBuildr_float__g9R9h {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes GraphBuildr_slide-in-fade__2RTJj {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes GraphBuildr_slide-in-fade__2RTJj {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.GraphBuildr_graphBuilder__3sXfq {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .GraphBuildr_graphBuilder__3sXfq .GraphBuildr_nodeEditorContainer__3b0Nf {
    flex: 1 1;
    flex-basis: 200px; }
  .GraphBuildr_graphBuilder__3sXfq .GraphBuildr_splitterHorizontal__2vdHH {
    border-bottom: 0 !important;
    border-top: 1px solid #000000 !important;
    height: 1px !important; }
  .GraphBuildr_graphBuilder__3sXfq .GraphBuildr_graphWrapper__xGeY- {
    height: 100%; }
  .theme-dark .GraphBuildr_graphBuilder__3sXfq .view-wrapper svg {
    background: #1a1e22; }
  .theme-dark .GraphBuildr_graphBuilder__3sXfq .GraphBuildr_pushDown__2AePr {
    -webkit-transform: translateY(55px);
            transform: translateY(55px); }
  .theme-dark .GraphBuildr_graphBuilder__3sXfq .GraphBuildr_toolbar__yYqlw {
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
  .GraphBuildr_graphBuilder__3sXfq .GraphBuildr_tag__2UxBk span {
    color: #141511; }

.view-wrapper {
  cursor: crosshair; }
  .theme-dark .view-wrapper .edge.selected .edge-text {
    fill: #6ab04c; }
  .theme-dark .view-wrapper .edge .edge-text {
    text-shadow: -1px -1px 0px #060809, 0px -1px 0px #060809, 1px -1px 0px #060809, -1px 0px 0px #060809, 1px 0px 0px #060809, -1px 1px 0px #060809, 0px 1px 0px #060809, 1px 1px 0px #060809, -2px -2px 0px #060809, -1px -2px 0px #060809, 0px -2px 0px #060809, 1px -2px 0px #060809, 2px -2px 0px #060809, 2px -1px 0px #060809, 2px 0px 0px #060809, 2px 1px 0px #060809, 2px 2px 0px #060809, 1px 2px 0px #060809, 0px 2px 0px #060809, -1px 2px 0px #060809, -2px 2px 0px #060809, -2px 1px 0px #060809, -2px 0px 0px #060809, -2px -1px 0px #060809; }

@-webkit-keyframes EntityToolbar_fade-in__1KAEJ {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@keyframes EntityToolbar_fade-in__1KAEJ {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@-webkit-keyframes EntityToolbar_pop__3f0NK {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes EntityToolbar_pop__3f0NK {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes EntityToolbar_float__37LP3 {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes EntityToolbar_float__37LP3 {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes EntityToolbar_slide-in-fade__2BvTG {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes EntityToolbar_slide-in-fade__2BvTG {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.EntityToolbar_wrapper__1PNfG {
  display: flex;
  flex-direction: column; }
  .theme-dark .EntityToolbar_wrapper__1PNfG {
    z-index: 12;
    position: absolute;
    width: 100%;
    -webkit-animation: EntityToolbar_slide-in-fade__2BvTG 0.3s ease-in-out;
            animation: EntityToolbar_slide-in-fade__2BvTG 0.3s ease-in-out;
    display: flex; }
    .theme-dark .EntityToolbar_wrapper__1PNfG .EntityToolbar_entityToolbar__1qzs2 {
      width: 100%;
      background: #1a1e22;
      border-bottom: 1px solid #060809; }
      .theme-dark .EntityToolbar_wrapper__1PNfG .EntityToolbar_entityToolbar__1qzs2 .EntityToolbar_content__3vYjL {
        display: flex; }
        .theme-dark .EntityToolbar_wrapper__1PNfG .EntityToolbar_entityToolbar__1qzs2 .EntityToolbar_content__3vYjL .EntityToolbar_label__1vLO9,
        .theme-dark .EntityToolbar_wrapper__1PNfG .EntityToolbar_entityToolbar__1qzs2 .EntityToolbar_content__3vYjL .EntityToolbar_actionButton__1PZv_ {
          margin-right: 10px; }
          .theme-dark .EntityToolbar_wrapper__1PNfG .EntityToolbar_entityToolbar__1qzs2 .EntityToolbar_content__3vYjL .EntityToolbar_label__1vLO9.EntityToolbar_active__2bYZc,
          .theme-dark .EntityToolbar_wrapper__1PNfG .EntityToolbar_entityToolbar__1qzs2 .EntityToolbar_content__3vYjL .EntityToolbar_actionButton__1PZv_.EntityToolbar_active__2bYZc {
            color: #6ab04c; }
        .theme-dark .EntityToolbar_wrapper__1PNfG .EntityToolbar_entityToolbar__1qzs2 .EntityToolbar_content__3vYjL .EntityToolbar_label__1vLO9 {
          min-width: 100px;
          max-width: 250px;
          justify-content: center;
          text-align: center;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          text-overflow: ellipsis; }
          .theme-dark .EntityToolbar_wrapper__1PNfG .EntityToolbar_entityToolbar__1qzs2 .EntityToolbar_content__3vYjL .EntityToolbar_label__1vLO9 .EntityToolbar_labelText__V6kVy {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis; }
          .theme-dark .EntityToolbar_wrapper__1PNfG .EntityToolbar_entityToolbar__1qzs2 .EntityToolbar_content__3vYjL .EntityToolbar_label__1vLO9 .EntityToolbar_entityId__riP9Z {
            font-size: 10px; }
        .theme-dark .EntityToolbar_wrapper__1PNfG .EntityToolbar_entityToolbar__1qzs2 .EntityToolbar_content__3vYjL .EntityToolbar_buttons__1a5uw {
          display: flex; }
        .theme-dark .EntityToolbar_wrapper__1PNfG .EntityToolbar_entityToolbar__1qzs2 .EntityToolbar_content__3vYjL .EntityToolbar_sep__3ehn7 {
          display: flex;
          align-items: center;
          border-right: 1px solid #060809;
          padding: 10px 20px; }
        .theme-dark .EntityToolbar_wrapper__1PNfG .EntityToolbar_entityToolbar__1qzs2 .EntityToolbar_content__3vYjL .EntityToolbar_dataViewerWrapper__T55yc {
          overflow: hidden; }

.theme-dark .ColorPicker_colorPicker__A8o_F {
  position: relative; }
  .theme-dark .ColorPicker_colorPicker__A8o_F .ColorPicker_picker__2o5Pw {
    position: absolute;
    top: 30px; }
  .theme-dark .ColorPicker_colorPicker__A8o_F .chrome-picker > div {
    background: #1a1e22; }
  .theme-dark .ColorPicker_colorPicker__A8o_F .chrome-picker svg {
    fill: #d3d3d3 !important;
    background: none !important; }
    .theme-dark .ColorPicker_colorPicker__A8o_F .chrome-picker svg:hover {
      fill: #6ab04c !important; }
  .theme-dark .ColorPicker_colorPicker__A8o_F .chrome-picker input {
    background: #151a1d;
    color: #d3d3d3 !important;
    height: auto !important;
    border: none !important;
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4) !important;
    padding: 6px;
    outline: none; }
  .theme-dark .ColorPicker_colorPicker__A8o_F .ColorPicker_circle__IJ4Wr {
    cursor: pointer;
    width: 16px;
    height: 16px;
    border-radius: 50%; }
  .theme-dark .ColorPicker_colorPicker__A8o_F .ColorPicker_buttonGroup__2Hx5- {
    background: #1a1e22; }
    .theme-dark .ColorPicker_colorPicker__A8o_F .ColorPicker_buttonGroup__2Hx5- .ColorPicker_btn__XkXOd {
      box-shadow: rgba(0, 0, 0, 0.3) 0 0 2px, rgba(0, 0, 0, 0.3) 0 4px 8px;
      margin-right: 0 !important;
      flex: 1 1; }

.theme-dark .EntityDataViewer_dataViewer__3McRU {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis; }
  .theme-dark .EntityDataViewer_dataViewer__3McRU .EntityDataViewer_entry__2vhg9 {
    outline: none;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-all;
    margin-right: 10px; }
    .theme-dark .EntityDataViewer_dataViewer__3McRU .EntityDataViewer_entry__2vhg9 label {
      font-weight: bold;
      color: #6ab04c;
      margin-right: 5px; }

.theme-dark .KeyValueEditor_keyValueEditor__3V3iA {
  flex: 1 1; }
  .theme-dark .KeyValueEditor_keyValueEditor__3V3iA .KeyValueEditor_actions__1nEqz {
    margin-left: auto; }
  .theme-dark .KeyValueEditor_keyValueEditor__3V3iA .KeyValueEditor_errors__1nXWw {
    color: #e74c3c; }
  .theme-dark .KeyValueEditor_keyValueEditor__3V3iA .KeyValueEditor_footer__dZpMl {
    padding: 10px 20px;
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #060809; }

.theme-dark .Entry_entry__1ABuf {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1px;
  position: relative; }
  .theme-dark .Entry_entry__1ABuf .Entry_tag__E1Ldz {
    position: absolute;
    right: 0;
    margin: 5px;
    font-size: 9px;
    z-index: 1;
    width: 65px; }
  .theme-dark .Entry_entry__1ABuf .Entry_valueWrapper__2-xiQ {
    position: relative;
    flex: 1 1;
    display: flex;
    align-items: center; }
    .theme-dark .Entry_entry__1ABuf .Entry_valueWrapper__2-xiQ .Entry_select__2_JlO.Entry_hasTypeTag__1_Izz .bp3-icon-double-caret-vertical {
      right: 75px; }
  .theme-dark .Entry_entry__1ABuf .Entry_input__xBQgW {
    flex: 1 1; }
    .theme-dark .Entry_entry__1ABuf .Entry_input__xBQgW.Entry_key__1naEc input {
      color: #6ab04c; }
  .theme-dark .Entry_entry__1ABuf .Entry_select__2_JlO {
    flex: 1 1;
    height: 100%; }
  .theme-dark .Entry_entry__1ABuf .Entry_input__xBQgW:first-child {
    margin-right: 5px; }

.theme-dark .Menu_menu__TMinI.Menu_secondary__2cqSY {
  color: #1a1e22;
  background: #ffffff; }
  .theme-dark .Menu_menu__TMinI.Menu_secondary__2cqSY .bp3-menu-divider {
    border-color: rgba(26, 30, 34, 0.1); }
  .theme-dark .Menu_menu__TMinI.Menu_secondary__2cqSY .bp3-menu-item:hover,
  .theme-dark .Menu_menu__TMinI.Menu_secondary__2cqSY .bp3-submenu
.bp3-popover-target.bp3-popover-open
.bp3-menu-item {
    background: !important; }


.auto-complete {
  flex: 1 1;
  position: relative; }
  .auto-complete .popover {
    width: 100%; }
  .auto-complete .bp3-popover-target {
    width: 100%; }
  .auto-complete .list {
    padding: 0; }

.theme-dark .HTMLSelect_htmlSelect__39ZtR {
  display: flex;
  align-items: center; }
  .theme-dark .HTMLSelect_htmlSelect__39ZtR select {
    background: #151a1d;
    color: #d3d3d3;
    height: 100%;
    outline: none; }
  .theme-dark .HTMLSelect_htmlSelect__39ZtR .bp3-icon-double-caret-vertical {
    top: unset; }

.TypeTag_typeTag__ogcuP {
  display: flex;
  justify-content: center; }
  .theme-dark .TypeTag_typeTag__ogcuP.TypeTag_string__2Xg4A {
    background-color: #e74c3c; }
  .theme-dark .TypeTag_typeTag__ogcuP.TypeTag_boolean__EwUZh {
    background-color: #3498db; }
  .theme-dark .TypeTag_typeTag__ogcuP.TypeTag_number__2TMiT {
    background-color: #16a085; }
  .TypeTag_typeTag__ogcuP span {
    display: flex;
    justify-content: center; }

.theme-dark .EntryLine_entryLine__3vy3w {
  display: flex;
  padding: 5px 0; }
  .theme-dark .EntryLine_entryLine__3vy3w .EntryLine_actions__Y2la6 {
    flex: 1 1;
    flex-basis: 80px;
    display: flex;
    align-items: center; }
    .theme-dark .EntryLine_entryLine__3vy3w .EntryLine_actions__Y2la6.EntryLine_empty__90Sen {
      flex: 0 1; }

@-webkit-keyframes EntityEditor_fade-in__2D9MI {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@keyframes EntityEditor_fade-in__2D9MI {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@-webkit-keyframes EntityEditor_pop__3nZ_v {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes EntityEditor_pop__3nZ_v {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes EntityEditor_float__Lfz1V {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes EntityEditor_float__Lfz1V {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes EntityEditor_slide-in-fade__1ontD {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes EntityEditor_slide-in-fade__1ontD {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.theme-dark div.EntityEditor_nodeEditor__1vkz6 {
  background: #1a1e22;
  border-bottom: 1px solid #060809;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%; }
  .theme-dark div.EntityEditor_nodeEditor__1vkz6 .EntityEditor_error__3nVLQ {
    background: #e74c3c;
    padding: 5px; }
  .theme-dark div.EntityEditor_nodeEditor__1vkz6 .EntityEditor_entries__1od7S {
    padding: 15px;
    max-height: 500px;
    overflow-y: auto; }
  .theme-dark div.EntityEditor_nodeEditor__1vkz6 .EntityEditor_editorContainer__20OxB {
    height: 100%; }

.GeneralToolbar_generalToolbar__1fhnS {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10; }
  .theme-dark .GeneralToolbar_generalToolbar__1fhnS .GeneralToolbar_help__33Gvm {
    pointer-events: none; }

@-webkit-keyframes GraphBuilder_fade-in__1qgQO {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@keyframes GraphBuilder_fade-in__1qgQO {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@-webkit-keyframes GraphBuilder_pop__1f4_m {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes GraphBuilder_pop__1f4_m {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes GraphBuilder_float__BoEzy {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes GraphBuilder_float__BoEzy {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes GraphBuilder_slide-in-fade__1RHCn {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes GraphBuilder_slide-in-fade__1RHCn {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.GraphBuilder_graphBuilder__2m-JX {
  flex: 2 1;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden; }
  .theme-dark .GraphBuilder_graphBuilder__2m-JX .GraphBuilder_firstInstructions__324pA {
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 10;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0; }
    .theme-dark .GraphBuilder_graphBuilder__2m-JX .GraphBuilder_firstInstructions__324pA p {
      color: white;
      font-family: 'Gloria Hallelujah', cursive;
      font-weight: 300;
      line-height: 44px; }
      .theme-dark .GraphBuilder_graphBuilder__2m-JX .GraphBuilder_firstInstructions__324pA p.GraphBuilder_start__JriMa {
        font-size: 34px; }
      .theme-dark .GraphBuilder_graphBuilder__2m-JX .GraphBuilder_firstInstructions__324pA p.GraphBuilder_keyboard__238kx {
        font-size: 26px;
        margin-bottom: 20px; }
    .theme-dark .GraphBuilder_graphBuilder__2m-JX .GraphBuilder_firstInstructions__324pA .GraphBuilder_arrowWrapper__2MTm4 {
      -webkit-animation: GraphBuilder_float__BoEzy 3s ease-in-out infinite;
              animation: GraphBuilder_float__BoEzy 3s ease-in-out infinite; }
      .theme-dark .GraphBuilder_graphBuilder__2m-JX .GraphBuilder_firstInstructions__324pA .GraphBuilder_arrowWrapper__2MTm4 svg g {
        stroke: white; }

.GraphBuilder_upToDate__2R-NB {
  position: relative;
  height: 100%; }

.GraphBuilder_notUpToDate__3C9fT {
  height: 100%;
  position: relative; }
  .GraphBuilder_notUpToDate__3C9fT g.node {
    opacity: 0.5; }

.GraphBuilder_graph__1aGJT {
  height: 100%;
  display: flex; }

g.node {
  cursor: pointer; }

.theme-dark .Dialog_dialog__16A3R .bp3-dialog-header {
  background: #1a1e22;
  padding: 15px;
  border-bottom: 1px solid #060809; }
  .theme-dark .Dialog_dialog__16A3R .bp3-dialog-header button {
    outline: none; }
    .theme-dark .Dialog_dialog__16A3R .bp3-dialog-header button:hover {
      color: #6ab04c; }
  .theme-dark .Dialog_dialog__16A3R .bp3-dialog-header .bp3-heading, .theme-dark .Dialog_dialog__16A3R .bp3-dialog-header button {
    color: #d3d3d3; }

.Dialog_dialog__16A3R.bp3-dialog {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3); }
  .theme-dark .Dialog_dialog__16A3R.bp3-dialog {
    background: #1a1e22;
    color: #d3d3d3 !important; }

.CreationDialog_creationDialog__oOiEV {
  padding-bottom: 0; }
  .theme-dark .CreationDialog_creationDialog__oOiEV {
    min-width: 660px;
    min-height: 360px;
    color: #d3d3d3; }
    .theme-dark .CreationDialog_creationDialog__oOiEV .CreationDialog_dialogContent__2hs_s {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      padding: 10px; }
      .theme-dark .CreationDialog_creationDialog__oOiEV .CreationDialog_dialogContent__2hs_s .CreationDialog_spinnerWrapper__ZLEYp {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1; }
      .theme-dark .CreationDialog_creationDialog__oOiEV .CreationDialog_dialogContent__2hs_s form {
        flex: 1 1;
        display: flex; }
        .theme-dark .CreationDialog_creationDialog__oOiEV .CreationDialog_dialogContent__2hs_s form .CreationDialog_editor__1FBJ_ {
          flex: 1 1;
          display: flex;
          justify-content: space-between;
          flex-direction: column; }
          .theme-dark .CreationDialog_creationDialog__oOiEV .CreationDialog_dialogContent__2hs_s form .CreationDialog_editor__1FBJ_ button {
            color: #d3d3d3; }
            .theme-dark .CreationDialog_creationDialog__oOiEV .CreationDialog_dialogContent__2hs_s form .CreationDialog_editor__1FBJ_ button:disabled {
              opacity: 0.4; }
        .theme-dark .CreationDialog_creationDialog__oOiEV .CreationDialog_dialogContent__2hs_s form .CreationDialog_footer__1Jpff {
          margin-top: 5px;
          flex: 0 1; }
      .theme-dark .CreationDialog_creationDialog__oOiEV .CreationDialog_dialogContent__2hs_s .CreationDialog_entries__2SCZC {
        /* background of the scrollbar except button or resizer */
        /* scrollbar itself */
        /* set button(top and bottom of the scrollbar) */
        height: 270px;
        overflow-y: auto; }
        .theme-dark .CreationDialog_creationDialog__oOiEV .CreationDialog_dialogContent__2hs_s .CreationDialog_entries__2SCZC::-webkit-scrollbar {
          background-color: transparent;
          width: 8px;
          height: 8px; }
        .theme-dark .CreationDialog_creationDialog__oOiEV .CreationDialog_dialogContent__2hs_s .CreationDialog_entries__2SCZC::-webkit-scrollbar-track {
          background: transparent; }
        .theme-dark .CreationDialog_creationDialog__oOiEV .CreationDialog_dialogContent__2hs_s .CreationDialog_entries__2SCZC::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 16px; }
        .theme-dark .CreationDialog_creationDialog__oOiEV .CreationDialog_dialogContent__2hs_s .CreationDialog_entries__2SCZC::-webkit-scrollbar-button {
          display: none; }
        .theme-dark .CreationDialog_creationDialog__oOiEV .CreationDialog_dialogContent__2hs_s .CreationDialog_entries__2SCZC::-webkit-scrollbar-corner {
          background: transparent; }
      .theme-dark .CreationDialog_creationDialog__oOiEV .CreationDialog_dialogContent__2hs_s .CreationDialog_callOut__1v8hv {
        margin: 10px 0; }
      .theme-dark .CreationDialog_creationDialog__oOiEV .CreationDialog_dialogContent__2hs_s .CreationDialog_reuse__11Cmy {
        padding-top: 10px; }

@-webkit-keyframes FirstInstructions_fade-in__1fqZE {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@keyframes FirstInstructions_fade-in__1fqZE {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@-webkit-keyframes FirstInstructions_pop__3Olly {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes FirstInstructions_pop__3Olly {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes FirstInstructions_float__2EaDH {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes FirstInstructions_float__2EaDH {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes FirstInstructions_slide-in-fade__bSVsV {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes FirstInstructions_slide-in-fade__bSVsV {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.theme-dark .FirstInstructions_firstInstructions__2fvzz {
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 10;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0; }
  .theme-dark .FirstInstructions_firstInstructions__2fvzz p {
    color: white;
    font-family: "Gloria Hallelujah", cursive;
    font-weight: 300;
    line-height: 44px; }
    .theme-dark .FirstInstructions_firstInstructions__2fvzz p.FirstInstructions_start__3lGrK {
      font-size: 34px; }
    .theme-dark .FirstInstructions_firstInstructions__2fvzz p.FirstInstructions_keyboard__2XIdd {
      font-size: 26px;
      margin-bottom: 20px; }
  .theme-dark .FirstInstructions_firstInstructions__2fvzz .FirstInstructions_arrowWrapper___1tIO {
    -webkit-animation: FirstInstructions_float__2EaDH 3s ease-in-out infinite;
            animation: FirstInstructions_float__2EaDH 3s ease-in-out infinite; }
    .theme-dark .FirstInstructions_firstInstructions__2fvzz .FirstInstructions_arrowWrapper___1tIO svg g {
      stroke: white; }

.theme-dark .GraphBuilderContextMenu_graphBuilderContextMenu__1sk6W {
  color: #1a1e22; }
  .theme-dark .GraphBuilderContextMenu_graphBuilderContextMenu__1sk6W .bp3-icon {
    color: #1a1e22; }
  .theme-dark .GraphBuilderContextMenu_graphBuilderContextMenu__1sk6W .bp3-menu {
    background: !important; }

.GraphBuilderContextMenu_menuHeading__cI9PB {
  padding-left: 5px; }

.ActionsToolbar_toolbarContainer__dJL8R {
  display: flex;
  flex-direction: column;
  flex: 1 1; }
  .theme-dark .ActionsToolbar_toolbarContainer__dJL8R .ActionsToolbar_toolbar__1uzFk {
    display: flex;
    justify-content: flex-end;
    padding: 10px; }
    .theme-dark .ActionsToolbar_toolbarContainer__dJL8R .ActionsToolbar_toolbar__1uzFk.ActionsToolbar_open__2S9K0 {
      background: #1a1e22;
      border-bottom: 1px solid #060809; }
    .theme-dark .ActionsToolbar_toolbarContainer__dJL8R .ActionsToolbar_toolbar__1uzFk .ActionsToolbar_button__1NcXq:disabled {
      pointer-events: none; }

.theme-dark ul.Instructions_instructions__32LMi {
  background: rgba(26, 30, 34, 0.81);
  padding: 0;
  list-style-type: none;
  border-bottom: 1px solid #060809;
  margin: 0; }
  .theme-dark ul.Instructions_instructions__32LMi > li {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .theme-dark ul.Instructions_instructions__32LMi .Instructions_keys__2Ych8 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 2 1; }
  .theme-dark ul.Instructions_instructions__32LMi .Instructions_key__MdUPP {
    background: #1a1e22;
    border-bottom: 4px solid #6ab04c;
    border-left: 1px solid #060809;
    border-right: 1px solid #060809;
    border-top: 1px solid #060809;
    color: #d3d3d3; }
  .theme-dark ul.Instructions_instructions__32LMi .Instructions_description__3UryF {
    text-align: left;
    flex: 9 1; }
  .theme-dark ul.Instructions_instructions__32LMi .Instructions_plus__NdPLC {
    margin: 0 5px; }

.theme-dark .Settings_graphSettings__1h9cN {
  background: #1a1e22; }

.theme-dark .PropList_propList__3sO2K {
  border-bottom: 1px solid #060809; }
  .theme-dark .PropList_propList__3sO2K .PropList_empty__2BDaX {
    padding: 10px;
    text-align: left; }
  .theme-dark .PropList_propList__3sO2K .PropList_collapseTrigger__1jcm- {
    display: flex;
    flex: 1 1; }
  .theme-dark .PropList_propList__3sO2K .PropList_list__1CbkP {
    list-style-type: none;
    padding: 5px 10px 15px;
    max-height: 350px;
    overflow: auto;
    /* background of the scrollbar except button or resizer */
    /* scrollbar itself */
    /* set button(top and bottom of the scrollbar) */ }
    .theme-dark .PropList_propList__3sO2K .PropList_list__1CbkP::-webkit-scrollbar {
      background-color: transparent;
      width: 8px;
      height: 8px; }
    .theme-dark .PropList_propList__3sO2K .PropList_list__1CbkP::-webkit-scrollbar-track {
      background: transparent; }
    .theme-dark .PropList_propList__3sO2K .PropList_list__1CbkP::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 16px; }
    .theme-dark .PropList_propList__3sO2K .PropList_list__1CbkP::-webkit-scrollbar-button {
      display: none; }
    .theme-dark .PropList_propList__3sO2K .PropList_list__1CbkP::-webkit-scrollbar-corner {
      background: transparent; }
    .theme-dark .PropList_propList__3sO2K .PropList_list__1CbkP li {
      padding: 5px 0; }
      .theme-dark .PropList_propList__3sO2K .PropList_list__1CbkP li.PropList_tableTitles__12Kq- {
        display: flex;
        justify-content: space-between;
        padding: 5px; }
        .theme-dark .PropList_propList__3sO2K .PropList_list__1CbkP li.PropList_tableTitles__12Kq- > div {
          flex: 1 1;
          text-align: left;
          border-bottom: 1px solid #060809;
          padding: 5px 0; }
  .theme-dark .PropList_propList__3sO2K .PropList_title__UkcyB {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #060809;
    padding: 10px;
    text-align: left; }
    .theme-dark .PropList_propList__3sO2K .PropList_title__UkcyB h3 {
      color: #6ab04c;
      margin: 0; }

.help_help__1HrPY {
  display: flex; }
  .help_help__1HrPY > :last-child {
    margin-left: 5px; }

.LabelItem_labelItem__3Z1N8 {
  display: flex;
  justify-content: space-between;
  padding: 5px; }
  .LabelItem_labelItem__3Z1N8 .LabelItem_propSelect__3kI1P {
    flex: 1 1; }
    .LabelItem_labelItem__3Z1N8 .LabelItem_propSelect__3kI1P select {
      padding: 5px; }
  .LabelItem_labelItem__3Z1N8 .LabelItem_label__noVCU {
    display: flex;
    align-items: center;
    flex: 1 1;
    text-align: left;
    margin-right: 5px; }
    .LabelItem_labelItem__3Z1N8 .LabelItem_label__noVCU span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }

.theme-dark .ExportDialog_exportDialog__BeA5i {
  min-width: 660px;
  min-height: 360px; }
  .theme-dark .ExportDialog_exportDialog__BeA5i .ExportDialog_footer__3uxmi {
    padding: 10px;
    display: flex;
    justify-content: flex-end; }
    .theme-dark .ExportDialog_exportDialog__BeA5i .ExportDialog_footer__3uxmi .ExportDialog_copy__QgUde {
      display: none; }
  .theme-dark .ExportDialog_exportDialog__BeA5i .ExportDialog_content__D_-P7 {
    flex: 1 1;
    display: flex; }
    .theme-dark .ExportDialog_exportDialog__BeA5i .ExportDialog_content__D_-P7 .ExportDialog_query__2ihMU {
      background: #151a1d;
      width: 100%;
      max-height: 330px;
      overflow: auto;
      color: #d3d3d3;
      font-size: 16px;
      margin: 0; }

.SchemaBuilder_schemaBuilder__3A-C2 {
  flex: 2 1;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden; }
  .SchemaBuilder_schemaBuilder__3A-C2 .SchemaBuilder_graph__gLl-Q {
    height: 100%;
    display: flex; }

.CreationDialog_creationDialog__3lAKq {
  padding-bottom: 0; }
  .theme-dark .CreationDialog_creationDialog__3lAKq {
    min-width: 660px;
    min-height: 360px;
    color: #d3d3d3; }
    .theme-dark .CreationDialog_creationDialog__3lAKq .CreationDialog_dialogContent__26MRc {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      padding: 10px; }
      .theme-dark .CreationDialog_creationDialog__3lAKq .CreationDialog_dialogContent__26MRc .CreationDialog_spinnerWrapper__1V0mZ {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1; }
      .theme-dark .CreationDialog_creationDialog__3lAKq .CreationDialog_dialogContent__26MRc form {
        flex: 1 1;
        display: flex; }
        .theme-dark .CreationDialog_creationDialog__3lAKq .CreationDialog_dialogContent__26MRc form .CreationDialog_editor__3amKJ {
          flex: 1 1;
          display: flex;
          justify-content: space-between;
          flex-direction: column; }
          .theme-dark .CreationDialog_creationDialog__3lAKq .CreationDialog_dialogContent__26MRc form .CreationDialog_editor__3amKJ button {
            color: #d3d3d3; }
            .theme-dark .CreationDialog_creationDialog__3lAKq .CreationDialog_dialogContent__26MRc form .CreationDialog_editor__3amKJ button:disabled {
              opacity: 0.4; }
        .theme-dark .CreationDialog_creationDialog__3lAKq .CreationDialog_dialogContent__26MRc form .CreationDialog_footer__D9Xaq {
          margin-top: 5px;
          flex: 0 1; }
      .theme-dark .CreationDialog_creationDialog__3lAKq .CreationDialog_dialogContent__26MRc .CreationDialog_entries__2Sbr4 {
        /* background of the scrollbar except button or resizer */
        /* scrollbar itself */
        /* set button(top and bottom of the scrollbar) */
        height: 270px;
        overflow-y: auto; }
        .theme-dark .CreationDialog_creationDialog__3lAKq .CreationDialog_dialogContent__26MRc .CreationDialog_entries__2Sbr4::-webkit-scrollbar {
          background-color: transparent;
          width: 8px;
          height: 8px; }
        .theme-dark .CreationDialog_creationDialog__3lAKq .CreationDialog_dialogContent__26MRc .CreationDialog_entries__2Sbr4::-webkit-scrollbar-track {
          background: transparent; }
        .theme-dark .CreationDialog_creationDialog__3lAKq .CreationDialog_dialogContent__26MRc .CreationDialog_entries__2Sbr4::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 16px; }
        .theme-dark .CreationDialog_creationDialog__3lAKq .CreationDialog_dialogContent__26MRc .CreationDialog_entries__2Sbr4::-webkit-scrollbar-button {
          display: none; }
        .theme-dark .CreationDialog_creationDialog__3lAKq .CreationDialog_dialogContent__26MRc .CreationDialog_entries__2Sbr4::-webkit-scrollbar-corner {
          background: transparent; }
      .theme-dark .CreationDialog_creationDialog__3lAKq .CreationDialog_dialogContent__26MRc .CreationDialog_callOut__k1D14 {
        margin: 10px 0; }
      .theme-dark .CreationDialog_creationDialog__3lAKq .CreationDialog_dialogContent__26MRc .CreationDialog_reuse__V6GaR {
        padding-top: 10px; }

.Alert_alert__N48kC.bp3-alert {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3); }
  .theme-dark .Alert_alert__N48kC.bp3-alert {
    background: #1a1e22;
    color: #d3d3d3 !important; }
    .theme-dark .Alert_alert__N48kC.bp3-alert button {
      outline: none;
      background: #1a1e22;
      color: #d3d3d3;
      border: none; }
      .theme-dark .Alert_alert__N48kC.bp3-alert button:hover {
        color: #6ab04c; }
      .theme-dark .Alert_alert__N48kC.bp3-alert button:not(.bp3-intent-danger) {
        box-shadow: none; }

.theme-dark .HeaderActions_headerActions__31lKy {
  display: flex;
  flex: 1 1;
  margin-left: 10px; }
  .theme-dark .HeaderActions_headerActions__31lKy .HeaderActions_user__1oa_Q {
    display: flex;
    align-items: center; }
  .theme-dark .HeaderActions_headerActions__31lKy .HeaderActions_menu__uA1O6 {
    margin-left: 10px; }
    .theme-dark .HeaderActions_headerActions__31lKy .HeaderActions_menu__uA1O6 button {
      min-width: 60px;
      min-height: 30px;
      margin-right: 30px; }
      .theme-dark .HeaderActions_headerActions__31lKy .HeaderActions_menu__uA1O6 button span {
        font-size: 14px; }

.MetadataDisplay_metadataDisplay__9oPG9 {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: 150px; }
  .MetadataDisplay_metadataDisplay__9oPG9 .MetadataDisplay_sep__zAwiX {
    margin: 0 5px; }
  .MetadataDisplay_metadataDisplay__9oPG9 .MetadataDisplay_input__ZDh25 input {
    background: none;
    font-size: 12px;
    padding: 0;
    border: none; }
    .MetadataDisplay_metadataDisplay__9oPG9 .MetadataDisplay_input__ZDh25 input:focus {
      box-shadow: none !important;
      padding: 0 !important; }
  .theme-dark .MetadataDisplay_metadataDisplay__9oPG9 {
    font-size: 12px; }
    .theme-dark .MetadataDisplay_metadataDisplay__9oPG9 .MetadataDisplay_username__1ahI2 {
      color: #6ab04c; }

.theme-dark .Router_main__2QF6u {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background: #1a1e22; }

.Router_main__2QF6u .Router_fallback__1Alf0 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.Workspaces_workspaces__yTSmh {
  overflow: auto; }
  .Workspaces_workspaces__yTSmh .Workspaces_breadCrumb__3Hyyx {
    padding: 0; }
  .Workspaces_workspaces__yTSmh .Workspaces_searchWrapper__3uVST {
    padding: 20px 0;
    display: flex; }
  .Workspaces_workspaces__yTSmh .Workspaces_wrapper__20VPr {
    display: flex;
    align-items: flex-start; }
    .Workspaces_workspaces__yTSmh .Workspaces_wrapper__20VPr .Workspaces_content__2t0ey {
      flex: 1 1;
      overflow: auto;
      margin: 50px;
      margin-top: 60px; }
      .Workspaces_workspaces__yTSmh .Workspaces_wrapper__20VPr .Workspaces_content__2t0ey .Workspaces_title__13n_y {
        text-align: left;
        margin: 0; }
      .Workspaces_workspaces__yTSmh .Workspaces_wrapper__20VPr .Workspaces_content__2t0ey .Workspaces_list__1NFt0 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px; }

@-webkit-keyframes GlobalLoader_fade-in__1Fg7P {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@keyframes GlobalLoader_fade-in__1Fg7P {
  0% {
    opacity: 0; }
  100% {
    opacity: 100%; } }

@-webkit-keyframes GlobalLoader_pop__iWT7X {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes GlobalLoader_pop__iWT7X {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  75% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes GlobalLoader_float__1mRgH {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes GlobalLoader_float__1mRgH {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes GlobalLoader_slide-in-fade__3Mb7s {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes GlobalLoader_slide-in-fade__3Mb7s {
  0% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.GlobalLoader_globalLoader__2PKvP {
  -webkit-animation: GlobalLoader_fade-in__1Fg7P 0.4s ease-in;
          animation: GlobalLoader_fade-in__1Fg7P 0.4s ease-in; }
  .theme-dark .GlobalLoader_globalLoader__2PKvP {
    background: rgba(26, 30, 34, 0.81);
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    top: 0;
    left: 0; }

.WorkspaceCard_workspaceCard__3i-O8 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  flex: 1 1;
  position: relative; }
  .WorkspaceCard_workspaceCard__3i-O8 .WorkspaceCard_snapshotWrapper__wIUMq {
    position: relative;
    overflow: hidden; }
    .WorkspaceCard_workspaceCard__3i-O8 .WorkspaceCard_snapshotWrapper__wIUMq .WorkspaceCard_snapshot__7j41q {
      display: flex;
      justify-content: center; }
    .WorkspaceCard_workspaceCard__3i-O8 .WorkspaceCard_snapshotWrapper__wIUMq .WorkspaceCard_overlay__IcAiT {
      display: none;
      background: rgba(0, 0, 0, 0.8);
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%; }
    .WorkspaceCard_workspaceCard__3i-O8 .WorkspaceCard_snapshotWrapper__wIUMq:hover .WorkspaceCard_overlay__IcAiT {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; }

.theme-dark .Footer_footer__XDtpQ {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1 1;
  padding: 10px;
  background: #151a1d; }
  .theme-dark .Footer_footer__XDtpQ .Footer_user__YC1EC {
    display: flex;
    align-items: center; }
    .theme-dark .Footer_footer__XDtpQ .Footer_user__YC1EC .Footer_avatar__3UlHC {
      margin-right: 5px; }
  .theme-dark .Footer_footer__XDtpQ .Footer_titleWrapper__33dtk {
    display: flex;
    justify-content: space-between;
    flex: 1 1;
    text-align: left; }
    .theme-dark .Footer_footer__XDtpQ .Footer_titleWrapper__33dtk .Footer_title__BSoI8 {
      color: #6ab04c;
      margin: 5px 0; }
    .theme-dark .Footer_footer__XDtpQ .Footer_titleWrapper__33dtk .Footer_keyId__31Q15 {
      color: #d3d3d3; }
    .theme-dark .Footer_footer__XDtpQ .Footer_titleWrapper__33dtk .Footer_description__3MiOq {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; }
    .theme-dark .Footer_footer__XDtpQ .Footer_titleWrapper__33dtk .Footer_privacyWrapper__2BUWo {
      display: flex;
      align-items: center; }
  .theme-dark .Footer_footer__XDtpQ .Footer_infoWrapper__EhMMf {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 5px 0;
    font-size: 10px;
    align-items: center; }

.theme-dark .Menu_menu__1zv6Y {
  min-width: 250px;
  margin: 50px; }
  .theme-dark .Menu_menu__1zv6Y .Menu_list__3V4CL {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .theme-dark .Menu_menu__1zv6Y .Menu_list__3V4CL .Menu_item__1skiV {
      padding: 10px;
      font-size: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      .theme-dark .Menu_menu__1zv6Y .Menu_list__3V4CL .Menu_item__1skiV:not(:last-child) {
        border-bottom: 1px solid #060809; }

.Filters_filters__3N5nH {
  margin-top: 20px; }
  .Filters_filters__3N5nH .Filters_title__3G0_o {
    text-align: left;
    margin-bottom: 20px;
    margin-left: 20px;
    font-size: 11px; }
  .Filters_filters__3N5nH .Filters_list__1qVU5 {
    list-style: none;
    margin: 0 0 0 20px;
    padding: 0; }
    .Filters_filters__3N5nH .Filters_list__1qVU5 .Filters_listItem__2uU3U {
      margin-bottom: 10px; }
      .Filters_filters__3N5nH .Filters_list__1qVU5 .Filters_listItem__2uU3U .Filters_userItem__YShtX {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer; }
        .Filters_filters__3N5nH .Filters_list__1qVU5 .Filters_listItem__2uU3U .Filters_userItem__YShtX:hover {
          opacity: 0.8; }
        .Filters_filters__3N5nH .Filters_list__1qVU5 .Filters_listItem__2uU3U .Filters_userItem__YShtX .Filters_userName__1Um_j {
          display: flex;
          align-items: center; }
        .Filters_filters__3N5nH .Filters_list__1qVU5 .Filters_listItem__2uU3U .Filters_userItem__YShtX .Filters_avatar__diuZI {
          margin-right: 10px; }

.Organization_organization__j_y6K {
  display: flex;
  flex-direction: column; }
  .theme-dark .Organization_organization__j_y6K .Organization_wrapper__8NLa9 {
    flex: 1 1;
    display: flex;
    justify-content: center; }
    .theme-dark .Organization_organization__j_y6K .Organization_wrapper__8NLa9 .Organization_container__1eM4N {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      max-width: 1200px; }
      .theme-dark .Organization_organization__j_y6K .Organization_wrapper__8NLa9 .Organization_container__1eM4N .Organization_content__Yldx6 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1; }
      .theme-dark .Organization_organization__j_y6K .Organization_wrapper__8NLa9 .Organization_container__1eM4N .Organization_footer__20R-v {
        max-width: 1200px;
        margin-top: 20px;
        display: flex;
        justify-content: flex-end; }

.theme-dark .EmptyState_emptyState__3GBDO .EmptyState_nonIdealDesc__14ObL .EmptyState_create__3EHfc {
  color: #6ab04c; }
  .theme-dark .EmptyState_emptyState__3GBDO .EmptyState_nonIdealDesc__14ObL .EmptyState_create__3EHfc:hover {
    cursor: pointer;
    text-decoration: underline; }

.theme-dark .EmptyState_emptyState__3GBDO .EmptyState_learnMore__EFrhH {
  margin: 30px 0;
  font-size: 11px; }

.Table_table__3qmb0 {
  border-spacing: 0;
  border-collapse: collapse;
  border-radius: 5px;
  width: 100%;
  flex: 1 1; }
  .theme-dark .Table_table__3qmb0 .Table_thead__W2OBr {
    position: -webkit-sticky;
    position: sticky;
    top: 0; }
    .theme-dark .Table_table__3qmb0 .Table_thead__W2OBr .Table_tr__368c8 {
      border: none; }
  .theme-dark .Table_table__3qmb0 .Table_th__1l9IP {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: #181c20;
    border-right: 1px solid #060809;
    border-top: 1px solid #060809;
    border-bottom: 1px solid #060809;
    color: #d3d3d3; }
    .theme-dark .Table_table__3qmb0 .Table_th__1l9IP:first-child {
      border-left: 1px solid #060809; }
    .theme-dark .Table_table__3qmb0 .Table_th__1l9IP .bp3-icon {
      margin-left: 5px; }
  .theme-dark .Table_table__3qmb0 .Table_tr__368c8 {
    border-bottom: 1px solid #131313;
    border-left: 1px solid #131313;
    border-right: 1px solid #131313; }
  .theme-dark .Table_table__3qmb0 .Table_td__3-11u, .theme-dark .Table_table__3qmb0 .Table_th__1l9IP {
    padding: 6px; }

.theme-dark .OrganizationData_organizationData__3gcaO {
  flex: 1 1;
  display: flex;
  justify-content: center; }
  .theme-dark .OrganizationData_organizationData__3gcaO .OrganizationData_content__3JKo7 {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .theme-dark .OrganizationData_organizationData__3gcaO .OrganizationData_content__3JKo7 .OrganizationData_actionButton__3E_Ka {
      padding: 0;
      min-width: unset;
      min-height: unset; }
    .theme-dark .OrganizationData_organizationData__3gcaO .OrganizationData_content__3JKo7 .OrganizationData_name__3ZdW4 {
      display: flex;
      align-items: center; }
      .theme-dark .OrganizationData_organizationData__3gcaO .OrganizationData_content__3JKo7 .OrganizationData_name__3ZdW4 .OrganizationData_nameText__36WXf {
        margin-left: 10px; }
    .theme-dark .OrganizationData_organizationData__3gcaO .OrganizationData_content__3JKo7 .OrganizationData_table__2hvSU .tr:nth-child(2n+1) .td {
      background: #2c353c; }
    .theme-dark .OrganizationData_organizationData__3gcaO .OrganizationData_content__3JKo7 .OrganizationData_table__2hvSU .OrganizationData_status__2CJTg {
      cursor: pointer; }

.FormDialog_createOrganization__fm4Dz {
  min-width: 820px; }
  .FormDialog_createOrganization__fm4Dz .FormDialog_content__3_ZJD {
    padding: 20px; }
    .FormDialog_createOrganization__fm4Dz .FormDialog_content__3_ZJD .FormDialog_footer__3hnx_ {
      display: flex;
      justify-content: flex-end; }

.theme-dark .Inviation_invitation__3Th1B {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .theme-dark .Inviation_invitation__3Th1B .Inviation_content__3GdSM .Inviation_logo__2h4CV {
    margin: 10px 0; }
  .theme-dark .Inviation_invitation__3Th1B .Inviation_content__3GdSM .Inviation_card__1rqaw {
    width: 400px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .theme-dark .Inviation_invitation__3Th1B .Inviation_content__3GdSM .Inviation_card__1rqaw strong {
      color: #6ab04c; }
    .theme-dark .Inviation_invitation__3Th1B .Inviation_content__3GdSM .Inviation_card__1rqaw .Inviation_button__tTB3g {
      margin: 10px 0; }

