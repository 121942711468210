@import "src/assets/stylesheets/mixins";

.workspaceCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  flex: 1;
  position: relative;

  .snapshotWrapper {
    position: relative;
    overflow: hidden;
    .snapshot {
      display: flex;
      justify-content: center;
    }

    .overlay {
      display: none;
      background: rgba(0, 0, 0, 0.8);
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    &:hover {
      .overlay {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
