@import "src/assets/stylesheets/mixins";

.menu {
  @include themify($themes) {
    min-width: 250px;
    margin: 50px;
    .list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      .item {
        padding: 10px;
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:not(:last-child) {
          border-bottom: 1px solid themed('blocksBorderColor');
        }
        .button {
        }
      }
    }
  }
}
