@import "src/assets/stylesheets/mixins";
@import "src/assets/stylesheets/animations";

.help {
  text-align: left;

  p {
    text-align: left;
    font-size: 12px;
  }

  @include themify($themes) {
    li {
      padding: 10px 0;
      line-height: 24px;

      :global(.bp3-tag) {
        background: themed('updateBackgroundColor');
        color: themed('tintColor');
      }
    }
  }
}
