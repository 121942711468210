@import "src/assets/stylesheets/mixins";

.entry {
  @include themify($themes) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1px;
    position: relative;

    .tag {
      position: absolute;
      right: 0;
      margin: 5px;
      font-size: 9px;
      z-index: 1;
      width: 65px;
    }

    .valueWrapper {
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;

      .select {
        &.hasTypeTag {
          :global(.bp3-icon-double-caret-vertical) {
            right: 75px;
          }
        }
      }
    }

    .input {
      flex: 1;
      &.key {
        input {
          color: themed('tintColor');
        }
      }
    }

    .select {
      flex: 1;
      height: 100%;
    }

    .input:first-child {
      margin-right: 5px;
    }
  }
}
