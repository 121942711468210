@import "./../../../../assets/stylesheets/variables";
@import "./../../../../assets/stylesheets/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  .editor {
  }
}
